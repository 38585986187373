import IconButton from '@Components/common/IconButton';

interface BreadCrumbProps {
  heading: string;
  overlayStatus?: () => void;
  onBackClick?: () => void;
}

const BreadCrumb = ({
  heading,
  overlayStatus,
  onBackClick,
}: BreadCrumbProps) => {
  const [beforeSlash, afterSlash] = heading.split(' / ');

  return (
    <div className="naxatw-flex naxatw-w-full naxatw-items-end naxatw-justify-start naxatw-gap-x-2 ">
      {onBackClick && (
        <IconButton
          name="arrow_back"
          iconClassName="naxatw-text-[#0B2E62]"
          className="naxatw-rounded-full !naxatw-font-normal naxatw-leading-6 naxatw-duration-200 hover:naxatw-bg-primary-200"
          onClick={() => {
            onBackClick();
            overlayStatus?.();
          }}
        />
      )}
      <div>
        <span
          className="!naxatw-text-[24px] !naxatw-font-bold naxatw-leading-[38px] naxatw-tracking-[-0.48px]
        naxatw-text-[#0B2E62]"
        >
          {beforeSlash} {afterSlash ? `/ ` : ''}
        </span>

        <span
          className="!naxatw-text-[24px] !naxatw-font-light naxatw-leading-[38px] naxatw-tracking-[-0.48px]
        naxatw-text-[#0B2E62]"
        >
          {afterSlash}
        </span>
      </div>
    </div>
  );
};

export default BreadCrumb;
