/* eslint-disable import/prefer-default-export */
import { FormFieldProps } from '@Constants/interface/FormInterface';

export const KnowledgeRepoFormFields: FormFieldProps[] = [
  {
    label: 'Title',
    inputType: 'text',
    type: 'input',
    placeholder: 'Title',
    id: 'title',
    name: 'title',
    isVisible: true,
    required: true,
  },
  {
    label: 'Sector',
    inputType: 'text',
    type: 'select',
    placeholder: 'Sector',
    id: 'thematic_field',
    name: 'thematic_field',
    multiple: true,
    checkBox: true,
  },
  {
    label: 'Type of Document',
    inputType: 'text',
    type: 'select',
    placeholder: 'Type of Document',
    id: 'file_type',
    name: 'file_type',
    required: true,
  },
  {
    label: 'Associated Programme / Project',
    inputType: 'text',
    type: 'accordion-select',
    placeholder: 'Associated Programme / Project',
    id: 'associate_program_project',
    name: 'associate_program_project',
    subGroupName: 'subCategories',
    enableSingleParent: true,
  },
  {
    label: 'Others',
    inputType: 'text',
    type: 'input',
    placeholder: 'Other Programmes',
    id: 'other_program_project',
    name: 'other_program_project',
    required: true,
  },
  {
    label: 'Published or Finalised Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'published_date',
    name: 'published_date',
    disabledDays: '',
    isVisible: true,
    required: true,
  },
  {
    label: 'Summary',
    inputType: 'text',
    type: 'textArea',
    className: ' naxatw-min-h-[4.5rem]',
    placeholder: 'Summary of the file',
    id: 'summary',
    name: 'summary',
    required: false,
  },
  {
    label: 'Tags',
    inputType: 'text',
    type: 'input',
    placeholder:
      'Enter tags for the document & if multiple tags, seperate with comma',
    id: 'tags',
    name: 'tags',
    required: false,
  },
  {
    label: 'Upload Documents',
    inputType: 'file',
    type: 'file-upload',
    placeholder: 'Please upload Documents ( Less than 50MB )',
    id: 'documents',
    name: 'documents',
    fileAccept: '*',
    multiple: true,
    maxSize: 50,
    required: true,
  },
];

export const KnowledgeRepoContextualFormFields: FormFieldProps[] = [
  {
    label: 'Title',
    inputType: 'text',
    type: 'input',
    placeholder: 'Title',
    id: 'title',
    name: 'title',
    isVisible: true,
    required: true,
  },
  {
    label: 'Thematic Field',
    inputType: 'text',
    type: 'select',
    placeholder: 'Thematic Field',
    id: 'thematic_field',
    name: 'thematic_field',
    multiple: true,
    checkBox: true,
  },
  {
    label: 'Type of File',
    inputType: 'text',
    type: 'select',
    placeholder: 'Type of File',
    id: 'file_type',
    name: 'file_type',
    required: true,
  },
  {
    label: 'Associated Programme / Project',
    inputType: 'text',
    type: 'accordion-select',
    placeholder: 'Associated Programme / Project',
    id: 'associate_program_project',
    name: 'associate_program_project',
    subGroupName: 'subCategories',
    enableSingleParent: true,
  },
  {
    label: 'Others',
    inputType: 'text',
    type: 'input',
    placeholder: 'Other Programmes',
    id: 'other_program_project',
    name: 'other_program_project',
    required: true,
  },
  {
    label: 'Province',
    inputType: 'text',
    type: 'select',
    placeholder: 'Province',
    id: 'province',
    name: 'province',
    required: true,
    enableSearchbar: false,
  },

  {
    label: 'Published or Finalised Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'published_date',
    name: 'published_date',
    disabledDays: '',
    isVisible: true,
    required: true,
  },
  {
    label: 'Summary',
    inputType: 'text',
    type: 'textArea',
    className: ' naxatw-min-h-[4.5rem]',
    placeholder: 'Summary of the file',
    id: 'summary',
    name: 'summary',
  },
  {
    label: 'Tags',
    inputType: 'text',
    type: 'input',
    placeholder:
      'Enter tags for the document & if multiple tags, seperate with comma',
    id: 'tags',
    name: 'tags',
  },
  {
    label: 'Upload Documents',
    inputType: 'file',
    type: 'file-upload',
    placeholder: 'Please upload Documents ( Less than 50MB )',
    id: 'documents',
    name: 'documents',
    fileAccept: '*',
    multiple: true,
    maxSize: 50,
    required: true,
  },
];

export type knowledgeRepoFormType = {
  name: string;
  thematic_field: number[] | null;
  file_type: string;
  associate_program_project: Record<string, any>;
  release_type: string;
  published_date: string;
  summary: string;
  documents: Record<string, any>[];
  documentToDelete: number | null;
  other_program_project?: string;
};

export const knowledgeRepoDefaultValues: knowledgeRepoFormType = {
  name: '',
  thematic_field: null,
  file_type: '',
  associate_program_project: {},
  release_type: '',
  published_date: '',
  summary: '',
  documents: [],
  documentToDelete: null,
  other_program_project: '',
};

export type knowledgeRepoDocumentResponseType = {
  file: string;
  id: number;
  knowledge_repository: number;
};

export const knowledgeRepoFormTypeInformation = [
  {
    label: 'Programmatic Information',
    id: 1,
    path: '/data-bank/knowledge-repository',
    activePaths: [
      '/data-bank/knowledge-repository',
      '/data-bank/knowledge-repository/add',
      '/data-bank/knowledge-repository/edit',
    ],
  },
  {
    label: 'Contextual Information',
    id: 2,
    path: '/data-bank/knowledge-repository/contextual',
    activePaths: [
      '/data-bank/knowledge-repository/contextual',
      '/data-bank/knowledge-repository/contextual/add',
      '/data-bank/knowledge-repository/contextual/edit',
    ],
  },
];

export const includeProvinces = [
  'Madhesh Province',
  'Lumbini Province',
  'Karnali Province',
];
