import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorMessage from '@Components/common/ErrorMessage';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import { FormControl } from '@Components/common/FormUI';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { provincialSummaryFormFields } from '@Constants/FormConstants/goalsForm';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { getProvincialSummary, patchProvincialSummary } from '@Services/goals';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import groupFormElements from '@Utils/index';
import isEmpty from '@Utils/isEmpty';

const ProvincialSummaryForm = ({ onClose }: any) => {
  const { provinceId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const locationParam = queryParams.get('location');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [actualSummary, setAcutalSummary] = useState<any>({});
  const formFields = groupFormElements(provincialSummaryFormFields);

  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
  });

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    dirtyFields,
    reset,
  };

  // post provincial summary
  const {
    mutateAsync: postProvincialSummaryData,
    isLoading: ispostProvincialSummaryData,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchProvincialSummary(payloadData),
  });

  // check edit fields
  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: Record<string, any> = {};
    Object.keys(allValues).forEach(key => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  const handleFormSubmit = async () => {
    try {
      const editFields = getDirtyFieldValues();
      if (!isEmpty(Object.keys(editFields))) {
        await postProvincialSummaryData({
          ...editFields,
          id: actualSummary?.id,
        });
        toast.success('Province Summary Updated Succesfully.');
        queryClient.invalidateQueries({
          queryKey: ['provinicial-summary'],
        });
        reset();
        onClose();
        navigate(`/data-bank/provincial-profile?location=${locationParam}`);
      }
    } catch (error) {
      toast.error('Error occured while submitting!');
    }
  };

  // fetched provincial summary
  const { isFetching: isProvincialSummaryFetching } = useQuery({
    queryKey: ['provinicial-summary', provinceId],
    queryFn: () => getProvincialSummary(provinceId),
    enabled: !!provinceId,
    select: data => data?.data[0],
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      if (data) {
        setAcutalSummary(data);
        Object.keys(data).map(key => setValue(key, data[key]));
      }
    },
  });

  return (
    <div
      className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-[90%] naxatw-max-w-[80%] naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-w-[34.75rem] xl:naxatw-w-[40%]"
      style={{ transform: 'translate(-50%, calc(-50% + 31.5px))' }}
    >
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading="Provincial Summary  / Form"
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>
      <div className="naxatw-flex naxatw-w-full naxatw-gap-3">
        <div className="naxatw-w-full">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
              {isProvincialSummaryFetching ? (
                <FormSkeleton numRows={8} className="naxatw-w-full" />
              ) : (
                <FlexColumn className="scrollbar naxatw-h-[calc(100vh-23.8rem)] naxatw-w-full  naxatw-space-y-5 naxatw-overflow-y-scroll naxatw-pr-2">
                  {formFields?.map((fieldRow: Record<string, any>) => (
                    <FlexRow
                      className="naxatw-gap-x-5 naxatw-gap-y-5"
                      key={fieldRow.id}
                    >
                      {fieldRow?.map((field: FormFieldProps) => {
                        const { id } = field;
                        if (!field?.isVisible) return null;
                        return (
                          field.isVisible && (
                            <FormControl
                              className="naxatw-w-full naxatw-gap-[0.25rem] "
                              key={`${field.id}-${field.name}`}
                            >
                              <InputLabel
                                label={field.label || ''}
                                id={field.id}
                              />
                              {getInputElement(
                                {
                                  ...field,
                                },
                                /* @ts-ignore */
                                formProps,
                              )}

                              {
                                /* @ts-ignore */
                                formProps.errors[id] && (
                                  <ErrorMessage
                                    /* @ts-ignore */
                                    message={formProps.errors[id]?.message}
                                  />
                                )
                              }
                            </FormControl>
                          )
                        );
                      })}
                    </FlexRow>
                  ))}
                </FlexColumn>
              )}
            </div>
            <div className="naxatw-flex  naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
              <Button
                size="normal"
                variant="primary"
                className="naxatw-px-4 naxatw-py-2"
                type="submit"
                isLoading={ispostProvincialSummaryData}
                disabled={ispostProvincialSummaryData}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default hasErrorBoundary(ProvincialSummaryForm);
