import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Arrow,
  Portal,
} from '@radix-ui/react-tooltip';
import Icon from '../common/Icon';

interface ToolTipProps {
  name: string;
  message: string;
  className?: string;
  messageStyle?: string;
  iconClick?: () => void;
  preventDefault?: boolean;
  type?: string;
}

export default function ToolTip({
  name,
  message,
  iconClick,
  className = 'naxatw-text-grey-500 hover:naxatw-text-black',
  messageStyle,
  preventDefault = true,
  type,
}: ToolTipProps) {
  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger
            onClick={e => {
              if (preventDefault) {
                e.preventDefault();
              }
              if (iconClick) iconClick();
            }}
          >
            <Icon name={name} type={type} className={`${className}`} />
          </TooltipTrigger>
          <Portal>
            <TooltipContent sideOffset={5} className="naxatw-z-50">
              <div
                className={`message naxatw-rounded-sm naxatw-bg-[#417EC9] naxatw-px-3 naxatw-py-1 naxatw-text-xs naxatw-font-semibold naxatw-text-white ${messageStyle}`}
              >
                {message}
              </div>
              <Arrow
                className="TooltipArrow naxatw-rounded"
                style={{ fill: '#417EC9' }}
              />
            </TooltipContent>
          </Portal>
        </Tooltip>
      </TooltipProvider>
    </>
  );
}

interface ITooltipWrapperProps {
  tooltipMessage: string;
  children: React.ReactNode;
  messageStyleClassName?: string;
  triggerStyleClassName?: string;
  triggerStyle?: Object;
  side?: 'top' | 'right';
  disabled: boolean;
}
const TooltipWrapper = ({
  tooltipMessage,
  children,
  triggerStyleClassName = 'naxatw-flex-1',
  messageStyleClassName = '',
  triggerStyle = {},
  side = 'top',
  disabled = false,
}: ITooltipWrapperProps) => {
  return disabled ? (
    <TooltipProvider>
      <Tooltip delayDuration={10}>
        <TooltipTrigger
          className={`${triggerStyleClassName} naxatw-cursor-not-allowed !naxatw-font-normal`}
          style={triggerStyle}
          type="button"
        >
          {children}
        </TooltipTrigger>
        <TooltipContent
          side={side ?? 'top'}
          sideOffset={0}
          className="naxatw-z-50"
        >
          <div
            className={`message naxatw-max-w-[20rem] naxatw-rounded-[0.25rem] naxatw-bg-gray-900 naxatw-px-3 naxatw-py-2 naxatw-text-center naxatw-text-sm naxatw-text-white  ${messageStyleClassName}`}
          >
            {tooltipMessage}
            <Arrow className="TooltipArrow" />
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <div className={`${triggerStyleClassName}`} style={triggerStyle}>
      {children}
    </div>
  );
};

export { TooltipWrapper };
