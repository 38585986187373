/* eslint-disable no-underscore-dangle */
import { v4 as uuidv4 } from 'uuid';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import {
  mapGovernmentStakeHolderColors,
  mapGovernmentStakeHoldersTitle,
} from '@Constants/mainDashboard';
import { useTypedSelector } from '@Store/hooks';

interface IPieChartLegendDetails {
  onClose: () => void;
  legendDetails: Record<string, any>;
}

const PieChartLegendDetails = ({
  onClose,
  legendDetails,
}: IPieChartLegendDetails) => {
  const showFilterColumn = useTypedSelector(
    state => state.mainDashboard.showFilterColumn,
  );
  return (
    <div className="chart-deatils naxatw-relative  naxatw-bg-white naxatw-p-3  naxatw-pt-11">
      <div className="back-btn naxatw-absolute naxatw-left-[-22px]">
        <Icon
          name="west"
          onClick={onClose}
          className="naxatw-text-xl naxatw-text-primary-600"
        />
      </div>
      <div className="deatils">
        {/* title */}
        <div className="details-title naxatw-mb-4 naxatw-flex naxatw-items-center naxatw-gap-2">
          <div className="naxatw-flex naxatw-flex-1 naxatw-items-center naxatw-gap-2">
            <div
              className="naxatw-h-[0.75rem] naxatw-w-[0.75rem] naxatw-rounded-[0.25rem]"
              style={{
                backgroundColor:
                  mapGovernmentStakeHolderColors[legendDetails?._id] || '',
              }}
            />
            <p className="naxatw-chart-text">
              {mapGovernmentStakeHoldersTitle[legendDetails?._id] || '-'}
            </p>
          </div>
          <p className="naxatw-chart-text">{legendDetails?.value || '-'}</p>
        </div>

        {/* program project and stakeholder data */}
        <div className="details-body">
          <div
            className={`title naxatw-mb-3 naxatw-flex naxatw-items-center naxatw-gap-2 ${showFilterColumn ? 'naxatw-justify-between' : ''}`}
          >
            <p
              className={`naxatw-chart-text ${showFilterColumn ? '' : 'naxatw-w-[14rem]'}`}
            >
              {legendDetails?.data[0]?.project_name ? 'Project' : 'Programme'}
            </p>
            <p className="naxatw-chart-text">Stakeholders</p>
          </div>
          <div className="">
            {/* programme_name */}
            {legendDetails?.data?.map((legendData: Record<string, any>) => {
              return (
                <div
                  className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-border-b naxatw-border-b-[#E3E3E3] naxatw-py-1 last:naxatw-border-0"
                  key={uuidv4()}
                >
                  <p
                    className="naxatw-line-clamp-1  naxatw-w-[14rem] naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-100"
                    title={
                      legendData?.programme_name ||
                      legendData?.project_name ||
                      '-'
                    }
                  >
                    {legendData?.programme_name ||
                      legendData?.project_name ||
                      '-'}
                  </p>
                  <p
                    className="naxatw-line-clamp-1 naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-100"
                    title={legendData?.stakeholder_name || '-'}
                  >
                    {legendData?.stakeholder_name || '-'}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(PieChartLegendDetails);
