/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { cn } from '@Utils/index';
import { FlexRow } from '@Components/common/Layouts';

interface ISwitchTabProps {
  title?: string;
  options: Record<string, any>;
  activeLabel: string;
  wrapperClassName?: string;
  className?: string;
  activeValue?: string;
  activeClassName?: string;
  onChange?: (label: string) => void;
  defaultBg?: boolean;
  titleClassName?: string;
  defaultBehaviour?: boolean;
}

const SwitchTab = ({
  options,
  title,
  activeLabel,
  activeValue,
  wrapperClassName,
  className,
  activeClassName,
  onChange,
  defaultBg = true,
  titleClassName,
  defaultBehaviour = true,
}: ISwitchTabProps) => {
  const [activeTab, setActiveTab] = useState(activeLabel);

  useEffect(() => {
    if (!activeValue) return;
    setActiveTab(activeValue);
  }, [activeValue]);

  return (
    <FlexRow
      className={cn(
        'naxatw-items-center naxatw-gap-[0.84rem]',
        wrapperClassName,
      )}
    >
      <p
        className={`${
          defaultBehaviour
            ? 'naxatw-body-caption naxatw-uppercase naxatw-tracking-wide naxatw-text-[#484848]'
            : titleClassName
        }`}
      >
        {title}
      </p>
      <FlexRow
        className={cn(
          'naxatw-items-center naxatw-rounded-lg naxatw-bg-[#F4F7FE] naxatw-p-[0.14044rem]',
          className,
        )}
      >
        {options?.map(({ label }: Record<string, any>) => (
          <span
            role="button"
            tabIndex={0}
            key={label}
            title={`${label}`}
            className={`naxatw-body-sm naxatw-cursor-pointer naxatw-select-none naxatw-rounded-lg naxatw-px-[0.5rem] naxatw-py-[0.4rem] naxatw-capitalize
              ${
                activeTab === label
                  ? cn(
                      `${
                        defaultBg
                          ? 'naxatw-bg-white naxatw-text-matt-100'
                          : 'naxatw-bg-[#833177] naxatw-text-white'
                      }   naxatw-shadow-[0px_0px_5px_0px_rgba(0,0,0,0.16)]`,
                      activeClassName,
                    )
                  : 'naxatw-text-matt-200 hover:naxatw-text-primary-700'
              } naxatw-duration-300`}
            onClick={() => {
              setActiveTab(label);
              if (onChange) {
                onChange(label);
              }
            }}
          >
            {label}
          </span>
        ))}
      </FlexRow>
    </FlexRow>
  );
};

export default SwitchTab;
