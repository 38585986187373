import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { signInUser } from '@Services/authentication';
import { useForm } from 'react-hook-form';
import InfoDialog from '@Components/common/InfoDialog';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import InputLabel from '@Components/common/InputLabel';
import { FormControl, Input } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';

const initialState = {
  username: '',
  password: '',
};

const Login = () => {
  const navigate = useNavigate();

  const [pwVisibility, setPwVisibility] = useState(false);
  const { mutate, isError, error, isLoading } = useMutation({
    mutationFn: signInUser,
    onSuccess: (res: any) => {
      localStorage.setItem('token', res.data.token);
      navigate('/');
    },
  });

  const { register, handleSubmit } = useForm({
    defaultValues: initialState,
  });

  const onSubmit = (data: any) => mutate(data);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="naxatw-flex naxatw-flex-col naxatw-gap-10"
    >
      <h6 className="naxatw-text-xl naxatw-font-medium naxatw-text-black">
        Login to your account
      </h6>
      {isError ? (
        <InfoDialog status="error">
          <span className="naxatw-text-[14px]">
            {/* @ts-ignore */}
            {error?.response?.data?.message || 'Invalid username or password'}
          </span>
        </InfoDialog>
      ) : null}
      <FlexColumn className="naxatw-gap-5">
        <FormControl>
          <InputLabel label="Username" />
          <Input
            id="username"
            placeholder="Enter your Username"
            type="text"
            className="naxatw-mt-2"
            {...register('username', { required: true })}
          />
        </FormControl>
        <FormControl className="naxatw-relative">
          <InputLabel label="Password" />
          <Input
            id="password"
            placeholder="Enter your Password"
            type={pwVisibility ? 'text' : 'password'}
            className="naxatw-mt-2"
            {...register('password', { required: true })}
          />
          <Icon
            name={pwVisibility ? 'visibility' : 'visibility_off'}
            onClick={() => setPwVisibility(!pwVisibility)}
            className="naxatw-absolute naxatw-right-4 naxatw-top-[56%] naxatw-select-none naxatw-text-xl"
          />
        </FormControl>
        <Button
          type="button"
          variant="link"
          className="naxatw-ml-auto naxatw-w-fit !naxatw-p-0 naxatw-font-medium"
          onClick={() => {
            navigate('/forgot-password');
          }}
        >
          <p className="naxatw-underline">Forgot password?</p>
        </Button>
      </FlexColumn>
      <FlexColumn>
        <Button type="submit" isLoading={isLoading}>
          Login
        </Button>
        <FlexRow className="naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
            Don&apos;t Have an Account?{' '}
          </p>
          <Button
            type="button"
            variant="link"
            className="naxatw-w-fit !naxatw-p-0 naxatw-font-medium"
            onClick={() => {
              navigate('/sign-up');
            }}
          >
            <p className="naxatw-underline">Sign up</p>
          </Button>
        </FlexRow>
      </FlexColumn>
    </form>
  );
};

export default Login;
