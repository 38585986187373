/* eslint-disable no-nested-ternary */
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import DataTable from '@Components/common/DataTable';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import { getProgrammeDetailsByProvince } from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ProgrammeDetailsTable = () => {
  const { provinceId } = useParams();
  const getQueryParams = useRouteParamQuery();
  const municipalityCode = getQueryParams.get('municipality');
  const { data: programmeDetails, isLoading: programmeDetailsIsLoading } =
    useQuery({
      queryKey: ['programme-details-table-data', provinceId, municipalityCode],
      queryFn: async () =>
        getProgrammeDetailsByProvince({
          province: provinceId,
          municipality: municipalityCode,
        }),
      select: res => res.data,
    });
  const navigate = useNavigate();

  const columns = [
    {
      header: 'FIRST TIER PARTNER',
      accessorKey: 'first_tier_partner',
    },
    {
      header: 'SECTOR',
      accessorKey: 'sector',
    },
    {
      header: 'Budget Allocated (£)',
      accessorKey: 'budget',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => (
        <span>{row?.original?.budget?.toLocaleString('en-us') || '-'}</span>
      ),
    },
  ];

  return (
    <>
      <div className="naxatw-w-full naxatw-rounded-[0.84275rem]  naxatw-border-[1.124px] naxatw-border-solid naxatw-border-grey-200 naxatw-bg-white naxatw-shadow-xs">
        {programmeDetailsIsLoading ? (
          <Skeleton className="naxatw-h-[10vh] naxatw-w-full" />
        ) : !isEmpty(programmeDetails) ? (
          <div className="table-content ">
            {programmeDetails.map((tableData: any, index: any) => (
              <div
                className="naxatw-mb-3 naxatw-px-5 naxatw-py-3 last:naxatw-mb-0"
                key={tableData.program_id}
              >
                <FlexRow className="naxatw-mb-3 naxatw-justify-between">
                  <Link
                    to={`/explore-by/programmes/${tableData.program_id}/`}
                    className="naxatw-text-base naxatw-font-medium naxatw-leading-5 naxatw-text-[#0B2E62]"
                  >
                    {index + 1}. {tableData.programs}
                  </Link>
                  <Link to={`/explore-by/programmes/${tableData.program_id}/`}>
                    <ToolTip
                      name="open_in_new"
                      message="Programme Details"
                      className="naxatw-rounded-full naxatw-p-1 naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
                      iconClick={() => {
                        navigate(
                          `/explore-by/programmes/${tableData.program_id}/`,
                        );
                      }}
                    />
                  </Link>
                </FlexRow>
                <DataTable
                  queryKey="programme details"
                  queryFn={() => {}}
                  columns={[
                    {
                      header: 'PROJECT',
                      accessorKey: 'project',
                      // eslint-disable-next-line react/no-unstable-nested-components
                      cell: row => {
                        return `${index + 1}.${Number(row?.row?.id) + 1}  ${row?.row?.original.project}`;
                      },
                    },
                    ...columns,
                    {
                      header: '',
                      accessorKey: 'icon',
                      // eslint-disable-next-line react/no-unstable-nested-components
                      cell: ({ row }) => {
                        return (
                          <Link
                            to={`/explore-by/programmes/${tableData?.program_id}/?componentId=${row?.original?.component_id}`}
                          >
                            <ToolTip
                              name="open_in_new"
                              className="naxatw-rounded-full naxatw-p-1 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
                              message="Component Details"
                              preventDefault
                              iconClick={() => {
                                navigate(
                                  `/explore-by/programmes/${tableData?.program_id}/?componentId=${row?.original?.project_id}`,
                                );
                              }}
                            />
                          </Link>
                        );
                      },
                    },
                  ]}
                  className="!naxatw-border-[0]"
                  demoData={tableData.data}
                  isPaginated={false}
                  searchInput=""
                  tableStyle={{ tableLayout: 'fixed' }}
                  // needSorting={false}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoChartDataComponent />
        )}
      </div>
    </>
  );
};

export default hasErrorBoundary(ProgrammeDetailsTable);
