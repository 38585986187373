import { FormFieldProps } from '@Constants/interface/FormInterface/index';

// eslint-disable-next-line import/prefer-default-export
export const PartnerFormFields: FormFieldProps[] = [
  {
    label: 'Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter the name of Organization (i.e. John Smith)',
    id: 'name',
    name: 'name',
    required: true,
  },
  {
    label: 'Type of First Tier Partner',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'organization_type',
    name: 'organization_type',
    choose: 'value',
    required: true,
  },
  {
    label: 'Supplier/Partner Code',
    inputType: 'number',
    type: 'input',
    placeholder: 'Enter Supplier/Partner Code',
    id: 'code',
    name: 'code',
    required: true,
  },
  {
    label: 'Address',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Organization Address',
    id: 'address',
    name: 'address',
    required: true,
  },
  // {
  //   label: 'Email',
  //   inputType: 'email',
  //   type: 'input',
  //   placeholder: 'Enter Organization Email',
  //   id: 'email',
  //   name: 'email',
  //   required: true,
  // },

  // {
  //   label: 'Phone Number',
  //   inputType: 'number',
  //   type: 'input',
  //   placeholder: 'Enter Organization Phone Number',
  //   id: 'phone',
  //   name: 'phone',
  //   required: true,
  // },

  {
    label: 'Website',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Organization Website',
    id: 'website',
    name: 'website',
  },
];

export const PersonDetailFormFields: FormFieldProps[] = [
  {
    label: 'Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter the name of Organization (i.e. John Smith)',
    id: 'name',
    name: 'name',
    required: true,
  },
  {
    label: 'Designation',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Designation',
    id: 'designation',
    name: 'designation',
    required: true,
  },
  {
    label: 'Email',
    inputType: 'email',
    type: 'input',
    placeholder: 'Enter Organization Email',
    id: 'email',
    name: 'email',
    group: 'group-1',
    required: true,
  },

  {
    label: 'Phone Number',
    inputType: 'number',
    type: 'input',
    placeholder: 'Enter Organization Phone Number',
    id: 'phone',
    name: 'phone',
    group: 'group-1',
    required: true,
  },
  {
    label: 'Upload Logo',
    inputType: 'file',
    type: 'file-upload',
    placeholder: 'Please upload picture (jpeg, png file format)',
    id: 'image',
    name: 'image',
    fileAccept: '*',
    multiple: false,
  },
];
