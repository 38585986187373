import Skeleton from '@Components/RadixComponents/Skeleton';
import { v4 as uuidv4 } from 'uuid';

interface IFormSkeletonProps {
  numRows?: number;
  className?: string;
}

const GoalSkeleton = ({ numRows = 1, className }: IFormSkeletonProps) => {
  const skeletonRows = Array.from({ length: numRows });

  return (
    <div className="goals-sk">
      {skeletonRows.map(() => (
        <div key={uuidv4()}>
          <Skeleton
            className={`naxatw-min-h-[480px] naxatw-w-full naxatw-rounded-xl  naxatw-border naxatw-border-gray-200 ${className} `}
          />
        </div>
      ))}
    </div>
  );
};

export default GoalSkeleton;
