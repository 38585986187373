/* eslint-disable no-underscore-dangle */
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { useDispatch } from 'react-redux';
import { setChartStates } from '@Store/actions/mainDashboard';
import {
  mapGovernmentStakeHolderColors,
  mapGovernmentStakeHoldersTitle,
  mapBudgetExpenditureColors,
  mapBudgetExpenditureTitle,
} from '@Constants/mainDashboard';
import { IChartProps } from '../types';

function getChartColor(chartId: string | undefined) {
  if (!chartId) return [];
  switch (chartId) {
    case 'budget-expenditure-type':
      return mapBudgetExpenditureColors;
    case 'government-stakeholders':
      return mapGovernmentStakeHolderColors;
    default:
      return [];
  }
}

function getChartTitle(chartId: string | undefined) {
  if (!chartId) return [];
  switch (chartId) {
    case 'budget-expenditure-type':
      return mapBudgetExpenditureTitle;
    case 'government-stakeholders':
      return mapGovernmentStakeHoldersTitle;
    default:
      return [];
  }
}

const CustomTooltip = ({ active, payload, chartId }: any) => {
  if (active && payload && payload.length) {
    const titleMapping: any = getChartTitle(chartId);

    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        {payload.map((tooltipItem: Record<string, any>) => {
          return (
            <div
              key={tooltipItem.name}
              className="naxatw-flex naxatw-items-center"
            >
              <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-grey-800">
                {titleMapping[tooltipItem.name] || '-'}
              </p>
              <span className="colon-separator naxatw-mx-1">:</span>
              <p className="naxatw-text-xs naxatw-font-bold  naxatw-tracking-[-0.015rem] naxatw-text-grey-800">
                {Number(tooltipItem?.value).toLocaleString() || '-'}%
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

const renderLabel = (label: any) => {
  return `${Number(label?.value)?.toFixed(2)?.toLocaleString()}%`;
};

const CustomPieChart = ({
  data,
  chartId,
  showPound,
  hasDetails,
}: IChartProps) => {
  const colorMapping: any = getChartColor(chartId);
  const dispatch = useDispatch();
  const COLORS = data.map(
    (pieData: Record<string, any>) => colorMapping[pieData._id] || '#8884d8',
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={600} height={400}>
        <Pie
          data={data}
          dataKey="percentage"
          nameKey="_id"
          cx="50%"
          cy="50%"
          outerRadius={150}
          labelLine={false}
          label={entry => renderLabel(entry)}
        >
          {data.map((singleObj: Record<string, any>, index: number) => (
            <Cell
              key={singleObj.value}
              fill={COLORS[index]}
              style={{ outline: 'none' }}
              className={hasDetails ? 'naxatw-cursor-pointer' : ''}
              onClick={() => {
                if (hasDetails) {
                  dispatch(
                    setChartStates({ key: 'chartDetails', value: singleObj }),
                  );
                  dispatch(
                    setChartStates({ key: 'showLegendDetails', value: true }),
                  );
                }
              }}
            />
          ))}
        </Pie>
        <Tooltip
          content={
            <CustomTooltip
              data={data}
              chartId={chartId}
              showPound={showPound}
            />
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
