import { useEffect } from 'react';
import { headerOptions } from '@Constants/exploreByGeography';
import { useTypedSelector, useTypedDispatch } from '@Store/hooks';
import { setSelectedTab } from '@Store/actions/portfolioProfiles';
import { Link, useLocation } from 'react-router-dom';

const ExploreHeader = () => {
  const dispatch = useTypedDispatch();
  const { selectedTab } = useTypedSelector(state => state.portfolioProfiles);

  const location = useLocation();
  const url = location.pathname.split('/')[2];

  useEffect(() => {
    dispatch(setSelectedTab(url));
  }, [dispatch, url]);

  return (
    <>
      <div className="naxatw-flex naxatw-justify-start">
        <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-justify-center naxatw-gap-4">
          {headerOptions.map(header => (
            <h5
              className={`naxatw-text-[0.875rem] ${
                selectedTab === header.value
                  ? 'naxatw-border-b-2 naxatw-border-blue-950 naxatw-py-2 naxatw-text-[#0B2E62]'
                  : 'naxatw-text-gray-500'
              }`}
              // onClick={()=>handleSectionChange(url)}
              key={header.id}
            >
              <Link
                to={`/explore-by/${header.value.toLocaleLowerCase()}`}
                className="naxatw-cursor-pointer naxatw-whitespace-nowrap naxatw-px-2 naxatw-text-sm naxatw-font-medium naxatw-leading-7 2xl:naxatw-text-xl"
              >
                {header.title}
              </Link>
            </h5>
          ))}
        </div>
      </div>
    </>
  );
};

export default ExploreHeader;
