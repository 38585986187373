/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

interface mainDashboardStates {
  showFilterColumn: boolean;
  map: Record<string, any>;
  activeBaseLayer: string;
  overlayLayerList: Record<string, any>[] | null;
  filterState: Record<string, any>[] | null;
  filterParams: Record<string, any>;
  filterCount: number;
  charts: Record<string, any>;
}

const initialState: mainDashboardStates = {
  map: {
    activeViewBy: 'province',
    activeStatus: 'all',
    activeSwicthIcon: 'map',
    activeFilterOption: 'program',
    isOverlayPopupVisible: false,
    showDownloadPopover: false,
  },
  showFilterColumn: false,
  activeBaseLayer: 'mapbox-light',
  overlayLayerList: null,
  filterState: null,
  filterCount: 0,
  filterParams: {
    program: [],
    component: [],
    firstTierPartner: [],
    sector: [],
    markerGroup: [],
    subMarkers: [],
    startDate: '',
    endDate: '',
    fileType: '',
    status: '',
  },
  charts: {
    showChartDetails: false,
    chartDetails: {},
  },
};

const setMainDashboardStates: CaseReducer<
  mainDashboardStates,
  PayloadAction<Record<string, any>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setDateRangeState: CaseReducer<
  mainDashboardStates,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const type = action?.payload?.type;
  if (type === 'startDate') {
    return {
      ...state,
      filterParams: {
        ...state.filterParams,
        startDate: action?.payload?.date,
      },
    };
  }

  if (type === 'clear')
    return {
      ...state,
      filterParams: {
        ...state.filterParams,
        startDate: '',
        endDate: '',
      },
    };

  return {
    ...state,
    filterParams: {
      ...state.filterParams,
      endDate: action?.payload?.date,
    },
  };
};

const setStatusFilterState: CaseReducer<
  mainDashboardStates,
  PayloadAction<string>
> = (state, action) => {
  return {
    ...state,
    filterParams: {
      ...state.filterParams,
      status: action.payload,
    },
  };
};

const setFilterCount: CaseReducer<mainDashboardStates> = state => {
  let checkedCount = 0;
  state?.filterState?.forEach((category: Record<string, any>) => {
    category.subcategories.forEach((subcategory: Record<string, any>) => {
      if (subcategory.checked) {
        checkedCount++;
      }
    });
  });
  if (state?.filterParams?.startDate && state?.filterParams?.endDate) {
    checkedCount++;
  }

  if (state?.filterParams?.status) {
    checkedCount++;
  }

  return {
    ...state,
    filterCount: checkedCount,
  };
};

const resetFilterState: CaseReducer<mainDashboardStates> = state => {
  const resetChecked = (data: any[]): any[] =>
    data?.map(category => ({
      ...category,
      checked: false,
      ...(category.subcategories && {
        subcategories: resetChecked(category.subcategories),
      }),
      ...(category.filterItems && {
        filterItems: resetChecked(category.filterItems),
      }),
    }));

  return {
    ...state,
    filterState: resetChecked(state.filterState as any),
    filterParams: {
      filterParams: {
        ...state.filterParams,
        startDate: '',
        endDate: '',
        status: '',
      },
    },
  };
};

// all the filter checkbox states managed by this reducer
// @ts-ignore
const toggleFilterState: CaseReducer<
  mainDashboardStates,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { categoryId, subcategoryId, filterId } = action.payload;

  return {
    ...state,
    filterState: state.filterState?.map(category => {
      if (category.id !== categoryId) return category;

      return {
        ...category,
        subcategories: category.subcategories
          ?.map((subcategory: Record<string, any>) => {
            if (subcategory.id !== subcategoryId) return subcategory;

            if (filterId) {
              const updatedFilterItems = subcategory.filterItems.map(
                (filterItem: Record<string, any>) => {
                  if (filterItem.id === filterId) {
                    return {
                      ...filterItem,
                      checked: !filterItem.checked,
                    };
                  }
                  return filterItem;
                },
              );

              const isAnyFilterItemChecked = updatedFilterItems.some(
                (filterItem: Record<string, any>) => filterItem.checked,
              );

              return {
                ...subcategory,
                filterItems: updatedFilterItems,
                checked: isAnyFilterItemChecked,
              };
            }

            return {
              ...subcategory,
              checked: !subcategory.checked,
              filterItems: subcategory.filterItems?.map(
                (filterItem: Record<string, any>) => {
                  if (subcategory.checked) {
                    return { ...filterItem, checked: false };
                  }
                  return { ...filterItem, checked: true };
                },
              ),
            };
          })
          .sort((a: Record<string, any>, b: Record<string, any>) => {
            return b.checked - a.checked;
          }),
      };
    }),
  };
};

// post filter Data
// @ts-ignore
const postFilterParams: CaseReducer<mainDashboardStates> = state => {
  const programmeIds: number[] = [];
  const componentIds: number[] = [];
  const firstTierPartnerIds: number[] = [];
  const sectorGroupIds: number[] = [];
  const markerGroupIds: number[] = [];
  const subMarkersIds: number[] = [];
  const fileType: string[] = [];

  state.filterState?.forEach(filterSta => {
    const { category, subcategories } = filterSta;

    subcategories.forEach((categoryItem: Record<string, any>) => {
      if (categoryItem.checked) {
        switch (category) {
          case 'Programme':
            programmeIds.push(categoryItem.id);
            categoryItem.filterItems.forEach((compId: Record<string, any>) => {
              if (compId.checked) componentIds.push(compId.id);
            });
            break;
          case 'First Tier Partner':
            firstTierPartnerIds.push(categoryItem.id);
            break;
          case 'Sector':
            sectorGroupIds.push(categoryItem.id);
            break;
          case 'Marker':
            subMarkersIds.push(categoryItem.id);
            categoryItem.filterItems.forEach(
              (subMarkerId: Record<string, any>) => {
                if (subMarkerId.checked) markerGroupIds.push(subMarkerId.id);
              },
            );

            break;

          case 'File Type':
            fileType.push(categoryItem.id);
            break;

          default:
            break;
        }
      }
    });
  });

  return {
    ...state,
    filterParams: {
      ...state.filterParams,
      program: programmeIds,
      component: componentIds,
      firstTierPartner: firstTierPartnerIds,
      sector: sectorGroupIds,
      markerGroup: markerGroupIds,
      subMarkers: subMarkersIds,
      fileType,
    },
  };
};

const handleOverlayLayerCheckbox: CaseReducer<
  mainDashboardStates,
  PayloadAction<number>
> = (state, action) => {
  const layerId = action.payload;
  if (state.overlayLayerList && layerId) {
    state.overlayLayerList = state.overlayLayerList.map(
      layer =>
        layer.id === layerId
          ? { ...layer, checked: !layer.checked }
          : { ...layer, checked: false }, // make all other layer unchecked
    );
  }
};

const setMapActiveViewBy: CaseReducer<
  mainDashboardStates,
  PayloadAction<string>
> = (state, action) => ({
  ...state,
  map: {
    ...state.map,
    activeViewBy: action.payload,
  },
});

const setShowFilterColumn: CaseReducer<
  mainDashboardStates,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const setLegendActiveFilter: CaseReducer<
  mainDashboardStates,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    map: {
      ...state.map,
      [key]: value,
    },
  };
};

const toggleOverlayPopup: CaseReducer<
  mainDashboardStates,
  PayloadAction<any>
> = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    map: {
      ...state.map,
      isOverlayPopupVisible: payload,
    },
  };
};

const setMapStates: CaseReducer<mainDashboardStates, PayloadAction<any>> = (
  state,
  action,
) => {
  const { key, value } = action.payload;

  return {
    ...state,
    map: {
      ...state.map,
      [key]: value,
    },
  };
};

const setChartStates: CaseReducer<mainDashboardStates, PayloadAction<any>> = (
  state,
  action,
) => {
  const { key, value } = action.payload;

  return {
    ...state,
    charts: {
      ...state.charts,
      [key]: value,
    },
  };
};

const mainDashboardSlice = createSlice({
  name: 'Main Dashboard',
  initialState,
  reducers: {
    setMainDashboardStates,
    handleOverlayLayerCheckbox,
    setShowFilterColumn,
    setMapActiveViewBy,
    setLegendActiveFilter,
    toggleOverlayPopup,
    toggleFilterState,
    resetFilterState,
    postFilterParams,
    setDateRangeState,
    setFilterCount,
    setMapStates,
    setStatusFilterState,
    setChartStates,
  },
});

export { mainDashboardSlice };

export default mainDashboardSlice.reducer;
