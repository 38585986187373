import { z } from 'zod';

export const KnowledgeRepositoryValidationSchema = z.object({
  title: z.string().trim().min(1, 'Title is Required'),
  thematic_field: z.array(z.number()).optional().nullable(),
  file_type: z.string().trim().min(1, 'File type is Required'),
  associate_program_project: z.record(z.any()).optional(),
  published_date: z.string().trim().min(1, 'Published date is Required'),
  documents: z.any().array().min(1, 'Document is Required'),
});

export const KnowledgeRepositoryConceptualValidationSchema = z.object({
  title: z.string().trim().min(1, 'Title is Required'),
  thematic_field: z.array(z.number()).optional().nullable(),
  file_type: z.string().trim().min(1, 'File type is Required'),
  associate_program_project: z.record(z.any()).optional(),
  province: z.number({ message: 'Province is Required' }),
  published_date: z.string().trim().min(1, 'Published date is Required'),
  documents: z.any().array().min(1, 'Document is Required'),
});

export type KnowledgeRepositorySchemaProps = z.infer<
  typeof KnowledgeRepositoryValidationSchema & {
    [key: string]: any;
  }
>;
