/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import DataTable from '@Components/common/DataTable';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexRow } from '@Components/common/Layouts';
import StatusChips from '@Components/common/StatusChips';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { mappedStatus } from '@Constants/mainDashboard';
import { getProgrammeDetails } from '@Services/provincialProfile';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { Link } from 'react-router-dom';

interface IProgrammeTableProps {
  className?: string;
  searchTerm?: string;
}

function ProgrammeTable({ className, searchTerm }: IProgrammeTableProps) {
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const componentFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const columns = [
    {
      header: 'FIRST TIER PARTNER',
      accessorKey: 'first_tier_partner',
      enableResizing: false,
    },
    {
      header: 'SECTOR',
      accessorKey: 'sector',
      enableResizing: false,
    },
    {
      header: 'WORKING AREA',
      accessorKey: 'working_province',
      enableResizing: false,
    },
    {
      header: 'Budget Allocated (£)',
      accessorKey: 'budget',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => (
        <span>{row?.original?.budget?.toLocaleString('en-us') || '-'}</span>
      ),
    },
    {
      header: 'Stakeholders',
      accessorKey: 'stakeholders',
    },
    {
      header: 'CAMPAIGN GOALS',
      accessorKey: 'campaign_goal',
      enableResizing: false,
    },
    {
      header: 'Status',
      accessorKey: 'project_status',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => (
        <div className="naxatw-mr-auto naxatw-py-4">
          <StatusChips
            label={row?.original?.project_status || ''}
            status={mappedStatus[row?.original?.project_status] || ''}
          />
        </div>
      ),
      size: 100,
    },
  ];

  const { data: programmeDetails, isLoading: programmeDetailsIsLoading } =
    useQuery({
      queryKey: [
        'programme-details-table-data',
        programmeFilterParams,
        componentFilterParams,
        firstTierPartnerParams,
        sectorGroupParams,
        markerGroupParams,
        subMarkersParams,
        startDate,
        endDate,
        statusFilterParams,
        searchTerm || '',
      ],
      queryFn: async () =>
        getProgrammeDetails({
          program: programmeFilterParams?.join(','),
          project: componentFilterParams?.join(','),
          first_tier_partner: firstTierPartnerParams?.join(','),
          sector: sectorGroupParams?.join(','),
          marker: markerGroupParams?.join(','),
          marker_category: subMarkersParams?.join(','),
          start_date: startDate,
          end_date: endDate,
          status: statusFilterParams,
          search_term: searchTerm || '',
        }),
      select: res => res.data,
    });

  return (
    <>
      <div
        className="scrollbar naxatw-h-full naxatw-w-full naxatw-overflow-auto"
        id="custom-scrollbar"
      >
        <div className={`table-section naxatw-h-full  ${className}`}>
          {programmeDetailsIsLoading ? (
            <Skeleton className="naxatw-h-[70vh] naxatw-w-full" />
          ) : !isEmpty(programmeDetails) ? (
            <div className="table-content naxatw-w-full naxatw-flex-grow">
              {programmeDetails.map((tableData: any, index: any) => (
                <div
                  className="naxatw-mb-3 naxatw-w-full naxatw-px-2 naxatw-py-3 last:naxatw-mb-0"
                  key={tableData.program_id}
                >
                  <FlexRow className="naxatw-mb-3 naxatw-items-center naxatw-gap-5">
                    <a
                      href={`/explore-by/programmes/${tableData.program_id}/`}
                      className="naxatw-text-base naxatw-font-medium naxatw-leading-5 naxatw-text-[#0B2E62]"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {index + 1}. {tableData.programs}
                    </a>
                    <Link
                      to={`/explore-by/programmes/${tableData.program_id}/`}
                    >
                      <ToolTip
                        name="open_in_new"
                        message="Programme Details"
                        className="naxatw-rounded-full naxatw-p-1 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
                        iconClick={() => {
                          window.open(
                            `/explore-by/programmes/${tableData.program_id}/`,
                            '_blank',
                          );
                        }}
                      />
                    </Link>
                  </FlexRow>
                  <DataTable
                    queryKey="programme details"
                    queryFn={() => {}}
                    columns={[
                      {
                        header: 'PROJECT',
                        accessorKey: 'project_id',
                        // eslint-disable-next-line react/no-unstable-nested-components
                        cell: row => {
                          return `${index + 1}.${Number(row?.row?.id) + 1}  ${row?.row?.original.project}`;
                        },
                        size: 100,
                      },
                      ...columns,
                      {
                        header: '',
                        accessorKey: 'icon',
                        size: 50,
                        // eslint-disable-next-line react/no-unstable-nested-components
                        cell: ({ row }) => {
                          return (
                            <a
                              href={`/explore-by/programmes/${tableData?.program_id}/?componentId=${row?.original?.component_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="naxatw-py-4 naxatw-pr-2"
                            >
                              <ToolTip
                                name="open_in_new"
                                className="naxatw-rounded-full naxatw-p-1 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
                                message="Project Details"
                                preventDefault
                                iconClick={() => {
                                  window.open(
                                    `/explore-by/programmes/${tableData?.program_id}/?componentId=${row?.original?.project_id}`,
                                    '_blank',
                                  );
                                }}
                              />
                            </a>
                          );
                        },
                      },
                    ]}
                    className="!naxatw-max-h-none !naxatw-rounded-[0px] !naxatw-border-[0]"
                    demoData={tableData.data}
                    isPaginated={false}
                    searchInput=""
                    tableStyle={{ tableLayout: 'fixed' }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <NoChartDataComponent className="naxtw-h-full" />
          )}
        </div>
      </div>
    </>
  );
}

export default hasErrorBoundary(ProgrammeTable);
