import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import AdminHeader from '@Components/common/AdminHeader';
import Portal from '@Components/common/Layouts/Portal';
import { useLocation, useNavigate } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import MarkerCategoriesTable from './Table';
import MarkerCategoriesForm from './Form';

const MarkerCategories = () => {
  const navigate = useNavigate();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <animated.div
      style={{ ...springs }}
      className="naxatw-relative naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
    >
      <AdminHeader
        title="Marker Categories"
        registerButtonText="Add"
        onAddNewClick={() => {
          navigate('/data-bank/markers/markers-categories/add');
        }}
      />
      <MarkerCategoriesTable />

      {toggleForm && (
        <Portal>
          <MarkerCategoriesForm
            onClose={() => {
              navigate('/data-bank/markers/markers-categories');
            }}
          />
        </Portal>
      )}
    </animated.div>
  );
};

export default hasErrorBoundary(MarkerCategories);
