/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from '@react-spring/web';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import {
  getProvinceData,
  getMunicipalityListByCode,
} from '@Services/portfolioProfile';
import Portal from '@Components/common/Layouts/Portal';
import Skeleton from '@Components/RadixComponents/Skeleton';
import SelectionBox from '@Components/common/PdfDesign/SelectionBox';
import { useTypedSelector } from '@Store/hooks';
import { provincialProfileCheckBoxData } from '@Constants/pdfGeneration';
import useScrollPosition from '@Hooks/useScrollPosition';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import IconHeader from '@Components/common/PdfDesign/IconHeaders';
import { resetCheckBoxProvincialProfile } from '@Store/actions/adminPdfAction';
import { getProvincialProfileReport } from '@Services/provincialProfile';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import useScreenWidth from '@Hooks/useScreenWidth';
import useWindowScrollAtEnd from '@Hooks/useWinndowScrollAtEnd';
import Sectors from './ChartSection/Sectors';
import ProgrammeDetails from './ChartSection/ProgrammeDetails';
import ProvincialPriorities from './ChartSection/ProvincialPriorities';
import Description from './Description';
import TopHeader from './TopHeader';
import Partners from './ChartSection/Partners';
import MapSection from './MapSection';
import './profile.css';
import ProvincailProfilePdfPopUp from './pdfDesign';

function ProvincialProfile() {
  const item1Ref = useRef<any>(null); // Reference for the first item
  const item2Ref = useRef<any>(null); // Reference for the second item
  const scrollRef = useRef<any>(null);
  const [item1Height, setItem1Height] = useState(0);
  const getQueryParams = useRouteParamQuery();
  const { provinceId } = useParams();
  const scrollPosition = useScrollPosition();
  const screenWidth = useScreenWidth();
  const dispatch = useDispatch();
  const { isAtBottom, scrollAfterBottomCount, resetScrollCount } =
    useWindowScrollAtEnd();

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  const [municipalityCode, setMunicipalityCode] = useState(
    getQueryParams.get('municipality') || '',
  );

  useEffect(() => {
    const code = getQueryParams.get('municipality') || '';
    setMunicipalityCode(code);
  }, [getQueryParams]);

  const { data: provinceData, isLoading: provinceDataIsLoading } = useQuery({
    queryKey: ['province-name', provinceId],
    queryFn: async () => getProvinceData(provinceId),
    select: (res: any) => res.data,
    enabled: !!provinceId,
  });

  const {
    name: provinceName,
    centroid_lat: provinceLat,
    centroid_lon: provinceLon,
  } = provinceData || {};

  const {
    data: municipalityData,
    // isLoading: isMunicipalityDataLoading
  } = useQuery({
    queryKey: ['getMunicipality', municipalityCode],
    queryFn: async () =>
      getMunicipalityListByCode(municipalityCode).then(res => res.data),
    enabled: !!municipalityCode,
  });

  const { name: municipalityName } = municipalityData || {};
  const [selectionBoxOpen, setSelectionBoxOpen] = useState(false);

  const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customIsLoading, setCustomIsLoading] = useState(true);
  const [stakeholderData, setStakeholderData] = useState<any[]>([]);

  function transformStakeholders(data: any[]) {
    const result: any[] = [];

    data?.forEach(stakeholder => {
      stakeholder.stakeholders?.forEach((levelObj: any) => {
        Object?.keys(levelObj)?.forEach(level => {
          const content = levelObj[level]?.map((categoryObj: any) => {
            const category = Object?.keys(categoryObj)[0];
            const items = categoryObj[category];
            return { category, items };
          });

          result.push({ level, content });
        });
      });
    });

    return result;
  }

  function handleClose() {
    setSelectionBoxOpen(false);
  }

  function handleExportClick() {
    setSelectionBoxOpen(true);
  }
  const provincialProfile = useTypedSelector(
    state => state.adminPdfSlice.provincialProfile,
  );

  useEffect(() => {
    setLoading(true);
  }, [municipalityCode]);

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  const scrollToBottomByPercentage = (percentage: number) => {
    if (scrollRef.current) {
      const scrollAmount = scrollRef.current.scrollHeight * (percentage / 100);
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollTop + scrollAmount,
        behavior: 'smooth',
      });
    }
  };
  const isScrollAtBottom = (scrollRefParam: any) => {
    if (scrollRefParam.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRefParam.current;
      // Small buffer to account for minor discrepancies
      return scrollTop + clientHeight >= scrollHeight - 1;
    }
    return false;
  };
  useEffect(() => {
    if (isAtBottom) {
      if (!isScrollAtBottom(scrollRef)) {
        scrollToBottomByPercentage(scrollAfterBottomCount * 7);
      }
    }
    if (scrollPosition === 0) {
      resetScrollCount();
      scrollToTop();
    }
  }, [isAtBottom, resetScrollCount, scrollAfterBottomCount, scrollPosition]);

  useEffect(() => {
    const updateHeight = () => {
      if (item1Ref.current) {
        const height = item1Ref.current.offsetHeight;
        setItem1Height(height);
        setLoading(false); // Height is set, stop loading
      }
    };

    // Defer height calculation until rendering is complete
    const timeoutId = setTimeout(updateHeight, 800);

    // Listen for window resize to recalculate height dynamically
    window.addEventListener('resize', updateHeight);

    // Cleanup listeners and timeout on unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', updateHeight);
    };
  }, [loading]);

  const { data: reportListData, isLoading: reportDataIsLoading } = useQuery({
    queryKey: ['reportList'],
    queryFn: async () => getProvincialProfileReport({ province: provinceId }),
    select: res => res.data[0],
  });
  useEffect(() => {
    setCustomIsLoading(true);
    if (reportDataIsLoading) return;
    setStakeholderData(transformStakeholders(reportListData?.stakeholder));
    setCustomIsLoading(false);
  }, [reportListData, reportDataIsLoading]);
  const hasSubNationalLevel = stakeholderData.some(
    item => item.level === 'Sub-National Level',
  );

  return (
    <>
      {pdfPreviewOpen && (
        <Portal className="naxatw-z-[111]">
          <ProvincailProfilePdfPopUp
            handleClose={() => {
              setPdfPreviewOpen(false);
              dispatch(resetCheckBoxProvincialProfile());
            }}
            checkList={provincialProfile}
            compoenentId="255"
            // data={pdfData}
          />
        </Portal>
      )}
      {selectionBoxOpen && (
        <Portal className="naxatw-z-[111]">
          <SelectionBox
            handleClose={() => handleClose()}
            handlePdfPreview={() => setPdfPreviewOpen(true)}
            checkBoxData={provincialProfileCheckBoxData}
            pdfType="provincialProfile"
          />
        </Portal>
      )}
      <animated.div style={{ ...springs }}>
        <div className="naxatw-mx-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-py-4">
          <TopHeader
            provinceName={provinceName || ''}
            isLoading={provinceDataIsLoading}
            municipalityName={municipalityName || ''}
            onClick={() => handleExportClick()}
          />

          <div
            className={`${scrollPosition > 0 ? 'naxatw-mt-[5%]' : 'naxatw-mt-0'} naxatw-flex naxatw-flex-col naxatw-gap-4 lg:naxatw-grid lg:naxatw-grid-cols-12`}
          >
            <div className="naxatw-col-span-4" ref={item1Ref}>
              <Description activeProvinceName={provinceData || {}} />
            </div>

            <div
              className="naxatw-col-span-8 naxatw-h-full naxatw-overflow-y-auto"
              id="right-section"
              ref={scrollRef}
              style={{
                height: screenWidth > 1024 ? `${item1Height}px` : '100%',
              }}
            >
              <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-4">
                <div className="w-full naxatw-h-[75vh] naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white">
                  <MapSection lat={provinceLat} lon={provinceLon} />
                </div>
                {!municipalityCode && (
                  <div className="naxatw-flex naxatw-flex-col naxatw-gap-4">
                    <ProvincialPriorities />
                    <Sectors />
                    <Partners />
                  </div>
                )}
                {loading ? (
                  <Skeleton className="naxatw-h-[7rem] naxatw-w-[5rem] naxatw-rounded-lg" />
                ) : (
                  <>
                    <div className="w-full">
                      <ProgrammeDetails />
                    </div>
                  </>
                )}
                {customIsLoading ? (
                  <FlexColumn className="naxatw-gap-2">
                    <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                    <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                    <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                    <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                  </FlexColumn>
                ) : (
                  stakeholderData.length > 0 &&
                  hasSubNationalLevel && (
                    <FlexColumn
                      className={`naxatw-gap-3 ${municipalityCode ? 'naxatw-hidden' : 'naxatw-block'}`}
                    >
                      <IconHeader
                        name="groups"
                        title="Stakeholders"
                        titleClassName="naxatw-text-base naxatw-font-bold !naxatw-text-matt-100"
                        iconClassName="!naxatw-text-lg !naxatw-text-matt-100"
                        className="naxatw-gap-2"
                      />
                      <FlexColumn className="naxatw-gap-3">
                        {stakeholderData?.map((stakeholder: any) => {
                          if (stakeholder.level === 'National Level')
                            return null;
                          return (
                            <FlexColumn
                              className="naxatw-gap-2"
                              key={stakeholder?.level}
                            >
                              <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-tracking-[0.03125rem] naxatw-text-primary-700">
                                {stakeholder?.level}
                              </p>
                              <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-3 naxatw-shadow-sm">
                                {stakeholder?.content?.map(
                                  (level: any, index: number) => {
                                    const isLastIndex =
                                      index === stakeholder.content.length - 1;
                                    return (
                                      <>
                                        <FlexColumn className="naxatw-gap-1">
                                          <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                            {level?.category}
                                          </p>
                                          <FlexRow className="naxatw-gap-[0.62rem]">
                                            {level?.items?.map(
                                              (
                                                subStakeholders: any,
                                                subIndex: number,
                                              ) => {
                                                const isLastSubIndex =
                                                  subIndex ===
                                                  level.items.length - 1;
                                                return (
                                                  <FlexRow
                                                    className="naxatw-gap-[0.62rem]"
                                                    key={subStakeholders}
                                                  >
                                                    <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-matt-200">
                                                      {subStakeholders}
                                                    </p>
                                                    <div
                                                      className={` ${isLastSubIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300 `}
                                                    />
                                                  </FlexRow>
                                                );
                                              },
                                            )}
                                          </FlexRow>
                                        </FlexColumn>
                                        <div
                                          className={` ${isLastIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200`}
                                        />
                                      </>
                                    );
                                  },
                                )}
                              </FlexColumn>
                            </FlexColumn>
                          );
                        })}
                      </FlexColumn>
                    </FlexColumn>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
}
export default hasErrorBoundary(ProvincialProfile);
