/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useTypedSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteSubSector, getSubSectorList } from '@Services/sectors';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const SectorTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [sectorIDToDelete, setsectorIDToDelete] = useState<string>('');

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['sectors/sector'],
  );

  const {
    mutate: deleteSectors,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteSubSector(sectorIDToDelete),
    onSuccess: () => {
      toast.success('Sector deleted successfully');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['sector-tabledata'],
      });
    },
  });

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'NAME',
      accessorKey: 'name',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() =>
                navigate(`/data-bank/sectors/sector/edit/${cellId}`)
              }
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setsectorIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];
  return (
    <div className="naxatw-h-[calc(100%-7rem)] naxatw-w-full lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="sector-tabledata"
        queryFn={getSubSectorList}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteSectors()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(SectorTable);
