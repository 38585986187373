import { useTypedSelector } from '@Store/hooks';
import isEmpty from '@Utils/isEmpty';

export default function MapInfoButton() {
  const selectedFeature = useTypedSelector(
    state => state.provincialProfile.selectedFeature,
  );
  return (
    <div className="actions naxatw-absolute naxatw-left-[45%] naxatw-top-[1.41rem] naxatw-z-10">
      <div className="naxatw-rounded-md naxatw-bg-[#A26499] naxatw-px-3 naxatw-py-2 naxatw-shadow-lg">
        <p className="naxatw-font-primary naxatw-text-[0.6rem] naxatw-font-semibold naxatw-text-white">
          {!selectedFeature || isEmpty(selectedFeature)
            ? 'Click on the map to view details'
            : 'Click outside of the map for default view'}
        </p>
      </div>
    </div>
  );
}
