/* eslint-disable import/prefer-default-export */
export function convertFilterData(inputData: Record<string, any>) {
  return [
    {
      id: 1,
      category: 'Programme',
      subcategories: inputData.program.map((prog: Record<string, any>) => ({
        id: prog.program__id,
        categoryId: 1,
        subcategory: prog.program__name,
        checked: false,
        filterItems: prog.projects
          .filter(
            (pro: Record<string, any>) => pro.id !== null && pro.name !== null,
          )
          .map((comp: Record<string, any>) => ({
            id: comp.id,
            filter: comp.name,
            subcategoryId: prog.program__id,
            checked: false,
          })),
      })),
    },
    {
      id: 2,
      category: 'First Tier Partner',
      subcategories: inputData.first_tier_partner.map(
        (partner: Record<string, any>) => ({
          id: partner.project__first_tier_partner__id,
          categoryId: 2,
          subcategory: partner.project__first_tier_partner__name,
          checked: false,
        }),
      ),
    },
    {
      id: 3,
      category: 'Sector',
      subcategories: inputData.sector.map((sector: Record<string, any>) => ({
        id: sector.project__project_sector__sector__id,
        categoryId: 3,
        subcategory: sector.project__project_sector__sector__name,
        checked: false,
        filterItems: [],
      })),
    },
    {
      id: 4,
      category: 'Marker',
      subcategories: inputData.marker.map((marker: Record<string, any>) => ({
        id: marker.id,
        categoryId: 4,
        subcategory: marker.name,
        checked: false,
        filterItems: marker.markers
          .filter(
            (submarker: Record<string, any>) =>
              submarker.id !== null && submarker.name !== null,
          )
          .map((submarker: Record<string, any>) => ({
            id: submarker.id,
            filter: submarker.name,
            subcategoryId: marker.id,
            checked: false,
          })),
      })),
    },
  ];
}

export function convertKnowledgeLibraryFilterData(
  inputData: Record<string, any>,
) {
  return [
    {
      id: 1,
      category: 'Programme',
      subcategories: inputData?.program?.map((prog: Record<string, any>) => ({
        id: prog?.knowledge_repository_program__associate_program__id,
        categoryId: 1,
        subcategory:
          prog?.knowledge_repository_program__associate_program__name,
        checked: false,
        filterItems: prog.projects
          .filter(
            (pro: Record<string, any>) => pro.id !== null && pro.name !== null,
          )
          .map((comp: Record<string, any>) => ({
            id: comp.id,
            filter: comp.name,
            subcategoryId:
              prog?.knowledge_repository_program__associate_program__id,
            checked: false,
          })),
      })),
    },
    {
      id: 2,
      category: 'Sector',
      subcategories: inputData.sector.map((sector: Record<string, any>) => ({
        id: sector.knowledge_repository_project__project__project_sector__sector__id,
        categoryId: 2,
        subcategory:
          sector.knowledge_repository_project__project__project_sector__sector__name,
        checked: false,
        filterItems: [],
      })),
    },
    {
      id: 3,
      category: 'File Type',
      subcategories: inputData.file_type.map(
        (fileType: Record<string, any>) => ({
          categoryId: 3,
          id: fileType.knowledge_repository__file_type,
          subcategory: fileType.knowledge_repository__file_type,
          cheked: false,
          filterItems: [],
        }),
      ),
    },
  ];
}
