/* eslint-disable no-nested-ternary */
import { FlexColumn } from '@Components/common/Layouts';
import { useTypedSelector } from '@Store/hooks';
import { convertToCurrencySystem, replaceUnderscores } from '@Utils/index';

export default function ChartDescription({
  data,
}: {
  data: Record<string, any>;
}) {
  const selectedTabOfCharts = useTypedSelector(
    state => state.setMainDashboardChartTabs.selectedTab,
  );

  return (
    <div
      className="chart-description-container naxatw-order-2 naxatw-h-full  naxatw-content-center naxatw-self-center naxatw-border-r naxatw-border-t naxatw-border-primary-200 naxatw-border-t-primary-200 naxatw-px-4 naxatw-py-4 md:naxatw-order-1 md:naxatw-border-t-0 lg:naxatw-px-8
    lg:naxatw-py-10 
    "
    >
      <FlexColumn className="!naxatw-flex-row naxatw-gap-8 md:!naxatw-flex-col">
        {data && Object.keys(data).length > 0 ? (
          Object.entries(data).map(([key, value]) => (
            <FlexColumn className="naxatw-gap-2" key={key}>
              <p className="naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-text-[1.125rem] naxatw-font-bold naxatw-text-matt-100 lg:naxatw-text-[1.5rem]">
                {key === 'total_count'
                  ? selectedTabOfCharts.govermentStakeHolders === 'Project'
                    ? 'Projects'
                    : 'Programmes'
                  : key === 'partner_count'
                    ? 'Implementing Partners'
                    : replaceUnderscores(key).split(' ')[0]}
              </p>
              <p className="naxatw-text-[2.125rem] naxatw-text-secondary-500">
                {' '}
                {key.includes('budget') || key.includes('expenditure')
                  ? `£${convertToCurrencySystem(value)}`
                  : value}
              </p>
            </FlexColumn>
          ))
        ) : (
          <h4>No Data Available</h4>
        )}
      </FlexColumn>
    </div>
  );
}
