import { z } from 'zod';

export const BasicInfoValidationSchema = z.object({
  name: z.string().trim().min(1, 'Name is Required'), // Using .min(1) as Zod does not have a .required() because it assumes fields are required by default
  address: z.string().trim().min(1, 'Address is Required'),
  organization_type: z.number({ message: 'Type is Required' }),
  code: z
    .string()
    .trim()
    .min(1, 'Supplier/Partner Code is Required')
    .max(20, 'Supplier/Partner Code should not be more than 20 characters'),
  // phone: z
  //   .string()
  //   .trim()
  //   .min(1, 'Phone number is required')
  //   .regex(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
  // email: z.string().email('Invalid email'),
  // Added to maintain consistency in error messaging, though not necessary because `.email()` implies requirement
});

export type BasicInfoValidationSchemaProps = z.infer<
  typeof BasicInfoValidationSchema & {
    [key: string]: any; // Adding an index signature
  }
>;
