/* eslint-disable no-nested-ternary */
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
// import { DatePicker } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import useOverallCountData from '@Hooks/getOverallCountData';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
// import convertToCurrencySystem from '@Utils/convertToCurrencySystem';
import useScrollPosition from '@Hooks/useScrollPosition';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface TopHeaderProps {
  provinceName: string;
  municipalityName?: string;
  isLoading?: boolean;
  onClick?: () => void;
}
const TopHeader = ({
  provinceName,
  municipalityName,
  isLoading,
  // eslint-disable-next-line no-unused-vars
  onClick,
}: TopHeaderProps) => {
  const { provinceId } = useParams();
  const scrollPosition = useScrollPosition();
  const getQueryParams = useRouteParamQuery();
  const municipalityCode = getQueryParams.get('municipality');
  const navigate = useNavigate();
  const location = useLocation();
  const navigatefromPage = location?.state?.from;

  const { data: provinceData, isLoading: provinceDataIsLoading } =
    useOverallCountData(
      municipalityCode
        ? { province: provinceId, municipality: municipalityCode }
        : { province: provinceId },
    );

  return (
    <>
      {provinceDataIsLoading || isLoading ? (
        <FormSkeleton numRows={3} />
      ) : (
        <div
          className={`${scrollPosition > 0 ? 'naxatw-fixed naxatw-top-[6.8vh] naxatw-pr-20 naxatw-pt-10' : 'naxatw-relative'} naxatw-z-[45] naxatw-w-full naxatw-bg-primary-100 naxatw-pb-5 lg:naxatw-max-w-[90rem]`}
        >
          <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between">
            <div className="naxatw-flex naxatw-items-center">
              <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                <ToolTip
                  name="arrow_back"
                  className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-[#0B2E62]"
                  iconClick={() =>
                    navigate(
                      navigatefromPage === 'dashboard-map'
                        ? '/dashboard/map'
                        : municipalityCode
                          ? `/explore-by/geography/${provinceId}`
                          : '/explore-by/geography/',
                    )
                  }
                  message="Back"
                />
                <h5 className="naxatw-text-xl naxatw-font-medium naxatw-text-[#0B2E62]">
                  {provinceName} {municipalityName && `/${municipalityName}`}
                </h5>
              </div>

              <div className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-px-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="27"
                  viewBox="0 0 2 27"
                  fill="none"
                >
                  <path d="M1 0V27" stroke="black" />
                </svg>

                <p className="naxatw-text-base naxatw-font-semibold naxatw-leading-normal naxatw-text-[#0B2E62]">
                  {provinceData?.program_count || 0} Programme &nbsp;
                  <span className="naxatw-text-sm naxatw-font-medium naxatw-text-[#667085]">
                    with &nbsp;
                  </span>
                  {provinceData?.project_count || 0} Projects &nbsp;
                  <span className="naxatw-text-sm naxatw-font-medium naxatw-text-[#667085]">
                    across &nbsp;
                  </span>
                  {provinceData?.sector_count || 0} Sectors
                </p>

                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="27"
                  viewBox="0 0 2 27"
                  fill="none"
                >
                  <path d="M1 0V27" stroke="black" />
                </svg> */}

                {/* <p className="naxatw-text-base naxatw-font-semibold naxatw-leading-normal naxatw-text-[#0B2E62]">
                  <span className="naxatw-text-sm naxatw-font-medium naxatw-text-[#667085]">
                    Allocated Budget &nbsp;
                  </span>
                  &#163;{' '}
                  {convertToCurrencySystem(provinceData?.allocated_budget) || 0}
                </p> */}
              </div>
            </div>
            {!municipalityCode && (
              <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
                {/* <DatePicker date="" placeHolder="Date Range" /> */}

                <Button
                  variant="secondary"
                  color="primary"
                  className="naxatw-text-sm"
                  onClick={onClick}
                >
                  <ToolTip
                    name="download"
                    className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-secondary-500"
                    message="Export"
                  />
                  Export
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TopHeader;
