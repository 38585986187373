/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@Components/RadixComponents/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { MultiStepType } from '@Constants/Types/type';
import { defaultPartnerRegistrationValues } from '@Constants/FormConstants/portfolioReportingConstants';
import {
  postPartnerReporting,
  postFocalPersonPartnerReporting,
  updatePartnerReporting,
  postWorkingAreasData,
  postOthersDPData,
  patchOthersDPData,
  postStakeholderData,
  patchStakeholderData,
  deleteWorkingArea,
  patchWorkingArea,
  deleteStakeholderData,
} from '@Services/portfolioReporting';
import { useMutation } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { splitArrayByKey } from '@Utils/index';
import { errorHandler } from '@Utils/errorHandler';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import ErrorLabel from '@Components/common/ErrorLabel';
import { animated, useSpring } from '@react-spring/web';
import { ComponentRegistrationValidationSchema } from '@Validations/ComponentUpdate/ComponentRegistrationValidationSchema';

import MultiStep from '../MultiStep';
import RegistrationForm from './RegistrationForm';
import StakeholdersForm from '../ComponentUpdateForm/StakeholdersForm';
import WorkingArea from '../ComponentUpdateForm/WorkingArea';

function prepareOtherDpData(data: Record<string, any>[]) {
  const modifiedData = data.reduce((finalData, dpData) => {
    dpData.development_partner?.map((partner: Record<string, any>) => {
      const payload = {
        location: dpData.id,
        intervention_sector: partner.intervention_sector,
        development_partner: partner.development_partner,
        reporting_id: partner.reporting_id,
        hasChanged: partner.hasChanged,
      };
      finalData.push(payload);
      return null;
    });
    return finalData;
  }, []);
  return modifiedData;
}

function prepareStakeholderData(data: Record<string, any>) {
  const { level_of_intervention, locationData, federalData } = data || {};

  const provinceModifiedData = locationData?.reduce(
    (finalData: Record<string, any>[], location: Record<string, any>) => {
      location.stakeholder.map((stakeholderData: Record<string, any>) => {
        stakeholderData.data.map((stake: Record<string, any>) => {
          const newPayload = {
            id: stake.id,
            province: location.id,
            stakeholder_type: stakeholderData.type,
            unit_ministry_department: stake.unit_ministry_department,
            unit_ministry: stake.unit_ministry,
            level_of_intervention: 'Sub-National Level',
            other_stakeholder: stake.other_stakeholder,
            reporting_id: stake.reporting_id,
            hasChanged: stake.hasChanged,
            isDelete: stake.isDelete,
            local_government_stakeholder: stake.local_government_stakeholder,
          };
          finalData.push(newPayload);
          return null;
        });
        return null;
      });
      return finalData;
    },
    [],
  );
  const federalModifiedData = federalData?.reduce(
    (finalData: Record<string, any>[], location: Record<string, any>) => {
      location.stakeholder.map((stakeholderData: Record<string, any>) => {
        stakeholderData.data.map((stake: Record<string, any>) => {
          const newPayload = {
            id: stake.id,
            province: null,
            stakeholder_type: stakeholderData.type,
            unit_ministry_department: stake.unit_ministry_department,
            unit_ministry: stake.unit_ministry,
            level_of_intervention: 'National Level',
            other_stakeholder: stake.other_stakeholder,
            reporting_id: stake.reporting_id,
            hasChanged: stake.hasChanged,
            isDelete: stake.isDelete,
          };
          finalData.push(newPayload);
          return null;
        });
        return null;
      });
      return finalData;
    },
    [],
  );

  return [
    ...(level_of_intervention?.['Sub-National Level']
      ? provinceModifiedData
      : []),
    ...(level_of_intervention?.['National Level'] ? federalModifiedData : []),
  ];
}

const registrationField = [
  'year',
  'reporting_period',
  'program',
  'component',
  'downstream_partner',
];

const portfolioReportingRegistrationSteps: MultiStepType[] = [
  {
    id: 1,
    value: 1,
    text: 'Basic Information',
  },
  {
    id: 2,
    value: 2,
    text: 'Working Areas',
  },
  {
    id: 3,
    value: 3,
    text: 'Stakeholders',
  },
];

const getPage = (page: number) => {
  switch (page) {
    case 1:
      return <RegistrationForm />;
    case 2:
      return <WorkingArea />;
    case 3:
      return <StakeholdersForm />;
    default:
      return <RegistrationForm />;
  }
};

const validationSchema = {
  1: ComponentRegistrationValidationSchema,
};

const PortfolioReportingRegistration = () => {
  const { portfolioID } = useParams();
  const navigate = useNavigate();
  const [focalPersonData, setFocalPersonData] = useState([]);
  const [page, setPage] = useState(1);
  const [apiError, setApiError] = useState(null);
  const formMethods = useForm({
    mode: 'all',
    resolver: zodResolver(
      validationSchema[page as keyof typeof validationSchema] ||
        ComponentRegistrationValidationSchema,
    ),
    defaultValues: defaultPartnerRegistrationValues,
  });

  // validation needs to be added
  const {
    disabled,
    stakeholderValidation,
    stakeholderValidationSubNational,
    subDisabled,
    workingAreasValidation,
  } = useSelector((state: any) => state.componentFormValidationSlice);

  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields },
    watch,
  } = formMethods;

  const {
    mutateAsync: postFocalPersonRegistrationData,
    // isLoading: isFocalPersonRegistrationLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postFocalPersonPartnerReporting(payloadData),
    onSuccess: () => {
      setApiError(null);
    },
    onError: error => {
      setApiError(errorHandler(error));
    },
  });

  const { mutateAsync: postWokingArea, isLoading: isWorkingAreaAdding } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        postWorkingAreasData(payloadData),
      onSuccess: () => {
        setApiError(null);
      },
      onError: error => {
        setApiError(errorHandler(error));
      },
    });

  const { mutateAsync: deleteWokingArea, isLoading: isWorkingAreaDeleting } =
    useMutation({
      mutationFn: (id: number) => deleteWorkingArea(id),
      onSuccess: () => {
        setApiError(null);
      },
      onError: error => {
        setApiError(errorHandler(error));
      },
    });

  const { mutateAsync: updateWokringArea, isLoading: isWorkingAreaUpdating } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) => {
        const { id, ...rest } = payloadData;
        return patchWorkingArea(id, rest);
      },
      onSuccess: () => {
        setApiError(null);
      },
      onError: error => {
        setApiError(errorHandler(error));
      },
    });

  // indicator others dp section
  const { mutateAsync: postOthersDPForm, isLoading: isOthersDPAdding } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        postOthersDPData(payloadData),
      onSuccess: () => {
        // toast.success('Budget Added Succesfully.');
      },
    });

  const { mutateAsync: patchOthersDPForm, isLoading: isOthersDPUpdating } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) => {
        const { reporting_id, ...rest } = payloadData;
        return patchOthersDPData(reporting_id, rest);
      },
      onSuccess: () => {
        // toast.success('Budget Added Succesfully.');
      },
    });

  // stakeholder section
  const { mutateAsync: postStakeholderForm, isLoading: isStakeholderAdding } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        postStakeholderData(payloadData),
      onSuccess: () => {
        setApiError(null);
      },
      onError: error => {
        setApiError(errorHandler(error));
      },
    });

  const {
    mutateAsync: patchStakeholderForm,
    isLoading: isStakeholderUpdating,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) => {
      const { reporting_id, ...rest } = payloadData;
      return patchStakeholderData(reporting_id, rest);
    },
    onSuccess: () => {
      setApiError(null);
    },
    onError: error => {
      setApiError(errorHandler(error));
    },
  });

  const { mutateAsync: deleteStakeholder, isLoading: isStakeholderDeleting } =
    useMutation({
      mutationFn: (id: number) => {
        return deleteStakeholderData(id);
      },
      onSuccess: () => {
        setApiError(null);
      },
      onError: error => {
        setApiError(errorHandler(error));
      },
    });

  // post partner reporting
  const {
    mutateAsync: postPartnerReportingData,
    isLoading: isPostPortfolioRegistrationLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postPartnerReporting(payloadData),
    onSuccess: response => {
      const partnerReportingId = response?.data?.id;
      if (!isEmpty(focalPersonData)) {
        const postFocalPersonData = focalPersonData?.map(
          (data: Record<string, any>) => ({
            ...data,
            partner_reporting: partnerReportingId,
          }),
        );

        //  post for focal person
        postFocalPersonData?.map(focalData => {
          return postFocalPersonRegistrationData(focalData);
        });
      }
      navigate(`/data-bank/project-data/edit/${partnerReportingId}`);
      setPage(page + 1);
      setApiError(null);
    },
    onError: (error: any) => {
      if (error.response.status === 400) {
        toast.error(error.response.data.detail || 'Project Already exists');
        navigate('/data-bank/project-data');
        setApiError(null);
      }
      setApiError(errorHandler(error));
    },
  });

  const {
    mutateAsync: updatePartnerReportingData,
    isLoading: isUpdatePortfolioRegistrationLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) => {
      const { id, ...rest } = payloadData;
      return updatePartnerReporting(id, rest);
    },
    onSuccess: () => {
      if (!isEmpty(focalPersonData)) {
        const postFocalPersonData = focalPersonData?.map(
          (data: Record<string, any>) => ({
            ...data,
            partner_reporting: portfolioID,
          }),
        );

        //  post for focal person
        postFocalPersonData?.map(focalData => {
          return postFocalPersonRegistrationData(focalData);
        });
      }
      setPage(page + 1);
      setApiError(null);
    },
    onError: error => {
      setApiError(errorHandler(error));
    },
  });

  const handleFormSubmit = async () => {
    const {
      first_tier_partner,
      reporting_period,
      program,
      component,
      downstream_partner,
      partner,
    } = getValues();
    const payloadValues = {
      year: new Date().toLocaleString()?.split(',')[0],
      reporting_period,
      program,
      project: component,
      downstream_partner: downstream_partner
        .map(partnerx => partnerx.value)
        .join('|'),
      partner,
    };
    const postFocalPersons = first_tier_partner?.focal_person?.filter(
      (person: Record<string, any>) => String(person.id).includes('new'),
    );
    setFocalPersonData(postFocalPersons);
    if (portfolioID) {
      if (
        registrationField.some(field =>
          Object.keys(dirtyFields).includes(field),
        )
      ) {
        await updatePartnerReportingData({ ...payloadValues, id: portfolioID });
      } else {
        setPage(page + 1);
      }
    } else {
      await postPartnerReportingData(payloadValues);
    }
  };

  const handleWorkingAreaFormSubmit = async () => {
    const { workingAreas } = getValues();
    const [postWorkingAreas, patchWorkingAreas] = splitArrayByKey(
      workingAreas?.workingAreas,
      'new',
    );

    if (!isEmpty(postWorkingAreas)) {
      const promises = postWorkingAreas
        ?.filter(area => !area?.isDelete)
        ?.map(async workingArea => {
          const payload = {
            area: workingArea.area,
            province: workingArea.province,
            district: workingArea.district,
            municipality: workingArea.municipality,
            project_data: portfolioID,
            start_date: workingArea.start_date,
            end_date: workingArea?.isActive ? '' : workingArea.end_date,
          };
          await postWokingArea(payload);
        });
      await Promise.all(promises);
    }
    if (!isEmpty(patchWorkingAreas)) {
      const patchPromises = patchWorkingAreas
        ?.filter(area => !area?.isDelete)
        ?.map(async workingArea => {
          const payload = {
            area: workingArea.area,
            province: workingArea.province,
            district: workingArea.district,
            municipality: workingArea.municipality,
            project_data: workingArea.partner_reporting,
            start_date: workingArea.start_date,
            end_date: workingArea?.isActive ? '' : workingArea.end_date,
            id: workingArea.id,
          };
          await updateWokringArea(payload);
        });
      await Promise.all(patchPromises);
    }

    if (!isEmpty(patchWorkingAreas)) {
      const promises = patchWorkingAreas
        ?.filter(area => area?.isDelete)
        ?.map(async workingArea => {
          await deleteWokingArea(workingArea?.id);
        });
      await Promise.all(promises);
    }
    setPage(page + 1);
  };

  const handleOtherDpsSubmit = async () => {
    const { otherDPs } = getValues();
    const otherDpData = prepareOtherDpData(otherDPs);
    const postData = otherDpData.filter(
      (partner: Record<string, any>) => !partner.reporting_id,
    );
    const patchData = otherDpData.filter(
      (partner: Record<string, any>) =>
        partner.reporting_id && partner.hasChanged,
    );

    const postTasks = !isEmpty(postData)
      ? postData.map((partner: Record<string, any>) => {
          return postOthersDPForm({
            ...partner,
            partner_reporting: portfolioID,
          });
        })
      : [];

    const patchTasks = !isEmpty(patchData)
      ? patchData.map((partner: Record<string, any>) => {
          return patchOthersDPForm({
            ...partner,
            partner_reporting: portfolioID,
          });
        })
      : [];

    await Promise.all([...postTasks, ...patchTasks]);

    navigate('/data-bank/project-data/');
  };
  const handleStakeholderSubmit = async () => {
    const { stakeholder } = getValues();
    const stakeholderData = prepareStakeholderData(stakeholder);

    const deleteStakeholderList = stakeholderData?.filter(data => {
      return data?.isDelete && !data?.id?.toString()?.includes('new');
    });

    const postPatchStakeholderData = stakeholderData?.filter(
      data => !data?.isDelete,
    );

    const postData = postPatchStakeholderData.filter(
      (partner: Record<string, any>) => !partner.reporting_id,
    );
    const patchData = postPatchStakeholderData.filter(
      (partner: Record<string, any>) =>
        partner.reporting_id && partner.hasChanged,
    );

    const postTasks = !isEmpty(postData)
      ? postData?.map((partner: Record<string, any>) => {
          return postStakeholderForm({
            ...partner,
            project_data: portfolioID,
          });
        })
      : [];

    const patchTasks = !isEmpty(patchData)
      ? patchData?.map((partner: Record<string, any>) => {
          return patchStakeholderForm({
            ...partner,
            project_data: portfolioID,
          });
        })
      : [];

    const deleteStakeholders = !isEmpty(deleteStakeholderList)
      ? deleteStakeholderList?.map((stake: Record<string, any>) => {
          return deleteStakeholder(stake.id);
        })
      : [];

    await Promise.all([...postTasks, ...patchTasks, ...deleteStakeholders]);
    navigate('/data-bank/project-data/');
  };

  const handleNextFormType = () => {
    if (page === 1) {
      handleFormSubmit();
    } else if (page === 2) {
      handleWorkingAreaFormSubmit();
    } else if (page === 3) {
      handleStakeholderSubmit();
    } else if (page === 4) {
      handleOtherDpsSubmit();
    } else {
      setPage(page + 1);
    }
  };
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });

  return (
    <animated.div
      className="naxatw-flex naxatw-w-full naxatw-justify-center md:naxatw-h-[calc(100vh-10rem)] md:naxatw-gap-6"
      style={{ ...props }}
    >
      <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-flex-col naxatw-space-y-6 md:naxatw-w-fit">
        <BreadCrumb
          heading="Project Data Form"
          onBackClick={() => navigate('/data-bank/project-data')}
        />

        <div className="naxatw-flex naxatw-w-[100%] naxatw-flex-col naxatw-gap-6 md:naxatw-h-[calc(100%-4rem)] lg:naxatw-flex-row">
          <div className="naxatw-w-full naxatw-gap-10 naxatw-rounded-2xl naxatw-bg-white naxatw-px-6 naxatw-py-5 md:naxatw-h-fit md:naxatw-min-w-[34.75rem] md:naxatw-max-w-[34.75rem] lg:naxatw-h-full lg:naxatw-min-w-[18.75rem] lg:naxatw-max-w-[18.75rem]">
            <div className="naxatw-flex naxatw-flex-col naxatw-gap-y-2.5">
              <MultiStep
                page={page}
                setPage={setPage}
                data={portfolioReportingRegistrationSteps}
              />
            </div>
          </div>

          <div className="naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white md:naxatw-h-full md:naxatw-w-[34.75rem] md:naxatw-min-w-[34.75rem] md:naxatw-max-w-[34.75rem]">
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(handleNextFormType)}
                className="naxatw-relative naxatw-flex naxatw-h-full naxatw-flex-col naxatw-pt-5"
              >
                <div className="scrollbar naxatw-flex naxatw-flex-1 naxatw-flex-col naxatw-overflow-y-scroll naxatw-px-5 sm:naxatw-px-6">
                  {getPage(page)}
                  {apiError && (
                    <div className="naxatw-py-1">
                      <ErrorLabel className="naxatw-ml-3" message={apiError} />
                    </div>
                  )}
                </div>

                <div className="naxatw-bg-white naxatw-px-5 naxatw-py-5 sm:naxatw-px-10">
                  <div
                    className={`naxatw-flex ${page === 1 ? 'naxatw-justify-end' : 'naxatw-justify-between'}`}
                  >
                    {page !== 1 && (
                      <Button
                        size="normal"
                        type="button"
                        onClick={e => {
                          e.preventDefault();
                          setPage(page - 1);
                        }}
                        variant="link"
                      >
                        <Icon name="arrow_back" />
                        Previous
                      </Button>
                    )}
                    <Button
                      size="normal"
                      type="submit"
                      variant="primary"
                      className="naxatw-w-fit"
                      isLoading={
                        isPostPortfolioRegistrationLoading ||
                        isUpdatePortfolioRegistrationLoading ||
                        isWorkingAreaAdding ||
                        isWorkingAreaDeleting ||
                        isOthersDPAdding ||
                        isOthersDPUpdating ||
                        isStakeholderAdding ||
                        isStakeholderUpdating ||
                        isStakeholderDeleting
                      }
                      // in disabled checks all states of stakeholders validation, if any of the state is true then it will disable the button
                      disabled={
                        (page === 3 &&
                          (disabled ||
                            Object.values(stakeholderValidation).some(
                              value => value === true,
                            ) ||
                            stakeholderValidationSubNational.some((obj: any) =>
                              Object.values(obj).some(value => value === true),
                            ) ||
                            Object.values(subDisabled).some(
                              value => value === true,
                            ))) ||
                        (page === 2 && workingAreasValidation)
                      }
                    >
                      {page === 3 ? (
                        <span>Save</span>
                      ) : (
                        <>
                          Next
                          <Icon name="arrow_forward" />
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default hasErrorBoundary(PortfolioReportingRegistration);
