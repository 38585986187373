import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import PartnersTable from '@Components/AdminPanel/Partners/PartnersTable';
import AdminHeader from '@Components/common/AdminHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Portal from '@Components/common/Layouts/Portal';
import PartnersForm from './PartnersForm';

const Partners = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [toggleForm, setToggleForm] = useState<boolean>(false);

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <animated.div
      style={{ ...springs }}
      className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
    >
      <AdminHeader
        title="First Tier Partners"
        registerButtonText="Add Partner"
        onAddNewClick={() => navigate('/data-bank/partners/add')}
        // hasBulkUpDownload
      />

      <PartnersTable />

      {toggleForm && (
        <Portal>
          <PartnersForm
            onClose={() => {
              navigate('/data-bank/partners');
              setToggleForm(false);
            }}
          />
        </Portal>
      )}
    </animated.div>
  );
};

export default hasErrorBoundary(Partners);
