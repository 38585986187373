/* eslint-disable react/no-unescaped-entities */
import exploreBg from '@Assets/images/bg-explore.png';
import { exploreCardData } from '@Constants/index';
import ExploreCard from './ExploreCard';

const Explore = () => {
  return (
    <div
      className="explore naxatw-relative naxatw-min-h-[calc(100vh-63px)] naxatw-bg-contain naxatw-bg-bottom naxatw-bg-no-repeat naxatw-p-4  max-[1380px]:naxatw-bg-cover lg:naxatw-bg-cover lg:naxatw-p-0 lg:naxatw-pt-0"
      style={{
        backgroundImage: `linear-gradient(170deg, rgba(239, 246, 254, 1) 0%, rgba(100, 159, 249, 0.20) 100%), url(${exploreBg})`,
      }}
    >
      <div className="naxatw-grid naxatw-place-items-center lg:naxatw-min-h-[calc(100vh-98px)] lg:naxatw-py-[5rem]">
        <div className="grid-content naxatw-text-center">
          {/* ----------------------- Heading ------------------- */}
          <div className="heading-container naxatw-py-[3.75rem]  lg:-naxatw-mt-[7rem]">
            <h2 className="naxatw-text-[2.5rem] naxatw-font-bold naxatw-leading-[normal] naxatw-tracking-[-0.075rem] naxatw-text-secondary-500 md:naxatw-text-[3.75rem]">
              Welcome to DVS
            </h2>
            <p className="naxatw-text-xl naxatw-leading-[2.04544rem] naxatw-text-matt-100 md:naxatw-text-[1.43181rem]">
              BEK's virtual portfolio hub to facilitate coordination,
              communication, and coherence
            </p>
          </div>

          {/* ----------------------- Explore Card ------------------- */}
          <div className="explore-card-container naxatw-grid naxatw-grid-cols-1 naxatw-justify-center  naxatw-gap-[1.12rem] lg:naxatw-grid-cols-[repeat(2,minmax(0,18.46875rem))] xl:naxatw-grid-cols-[repeat(4,minmax(0,18.46875rem))]">
            {exploreCardData.map(card => (
              <ExploreCard key={card.id} {...card} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
