import pdfSvg from '@Assets/images/pdf.svg';
import docSvg from '@Assets/images/doc.svg';
import textSvg from '@Assets/images/text.svg';
import xlsSvg from '@Assets/images/sheets.svg';
import csvSvg from '@Assets/images/csv.svg';
import { format, parseISO } from 'date-fns';
import getFileExtension from '@Utils/getFileExtension';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useRef } from 'react';
import useSeeMoreButton from '@Hooks/useSeeMoreButton';

function getFileLogo(fileType: string) {
  switch (fileType) {
    case 'pdf':
      return pdfSvg;
    case 'doc':
      return docSvg;
    case 'docx':
      return docSvg;
    case 'txt':
      return textSvg;
    case 'xls':
      return xlsSvg;
    case 'xlsx':
      return xlsSvg;
    case 'csv':
      return csvSvg;
    default:
      return pdfSvg;
  }
}

const KnowledgeCard = ({ repository }: { repository: Record<string, any> }) => {
  const summaryRef = useRef(null);
  const {
    program,
    // component,
    // thematic_field: sector,
    file_type: fileType,
    published_date: date,
    summary,
    tags,
  } = repository;

  const allTags = tags?.split(',')?.filter((tag: string) => tag !== '');
  const { isOpen, setIsOpen, showSeeMoreBtn } = useSeeMoreButton(summaryRef);

  return (
    <div className="naxatw-w-full naxatw-border-b naxatw-border-[#D7D7D7] naxatw-p-5 last:naxatw-border-b-0">
      <p className="naxatw-mb-5 naxatw-text-base naxatw-font-medium naxatw-leading-5 naxatw-text-[#0B2E62]">
        {repository?.title || '-'}
      </p>
      <div className="naxatw-grid naxatw-gap-[1.2rem] md:naxatw-grid-cols-[1fr_1.2fr]">
        <div>
          <div className="top-details naxatw-mb-6 naxatw-flex naxatw-flex-col naxatw-gap-2">
            {/* program */}
            <div className="naxatw-flex naxatw-items-start naxatw-gap-2">
              <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                Programme:{' '}
              </p>
              <FlexColumn className="naxatw-gap-[0.62rem]">
                {program?.map((programmeDetails: any) => (
                  <FlexColumn key={programmeDetails.project__program__id}>
                    <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                      {programmeDetails.project__program__name}
                    </p>
                    {programmeDetails?.projects?.map(
                      (project: any, index: number) => {
                        const isLastIndex =
                          index === programmeDetails.projects.length - 1;
                        return (
                          <FlexRow
                            key={project.project_id}
                            className="naxatw-items-center naxatw-gap-[0.62rem]"
                          >
                            <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                              {project.project_name}
                            </p>
                            {!isLastIndex && (
                              <div className="naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300" />
                            )}
                          </FlexRow>
                        );
                      },
                    )}
                  </FlexColumn>
                ))}
              </FlexColumn>
            </div>
            {/* sector */}
            {/* <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
              <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                Sector:{' '}
              </p>
              <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                {sector || '-'}
              </p>
            </div> */}
          </div>
          <div className="bottom-details naxatw-flex naxatw-flex-col naxatw-gap-6">
            {/* date */}
            <div className="naxatw-flex naxatw-items-center naxatw-gap-3 naxatw-text-xs naxatw-font-medium">
              <p className="naxatw-rounded-[50px] naxatw-bg-[#CFE0F1] naxatw-px-2 naxatw-py-1 naxatw-text-matt-200">
                {fileType || '-'}
              </p>
              <div className="naxatw-flex naxatw-items-center naxatw-gap-1">
                <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                  Uploaded on:
                </p>
                <span className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                  {' '}
                  {format(parseISO(date), 'MMMM d, yyyy') || '-'}
                </span>
              </div>
            </div>

            {/* download */}
            <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-gap-2">
              <p className="naxatw-text-base naxatw-font-medium naxatw-leading-5 naxatw-text-matt-200">
                Download
              </p>
              {repository?.documents &&
                repository?.documents.map((doc: Record<string, any>) => (
                  <div key={doc.id} className="naxatw-tooltip">
                    <a
                      href={doc.file}
                      download
                      target="_blank"
                      className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-text-xs naxatw-text-[#475467]"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          getFileLogo(
                            getFileExtension(doc?.file)?.split('?')[0] || '-',
                          ) || '-'
                        }
                        alt="file"
                        className="naxatw-h-7 naxatw-w-7 naxatw-cursor-pointer"
                      />
                      <span className="naxatw-tooltiptext">
                        {doc.file.split('/').pop()}
                      </span>
                    </a>
                  </div>
                ))}
            </div>

            {/* tags */}
            <div className="tags naxatw-flex naxatw-items-center naxatw-gap-3">
              <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                Tags:
              </p>
              <div className="tags-container naxatw-flex naxatw-items-center naxatw-gap-1">
                {!isEmpty(allTags)
                  ? allTags.map((tag: string) => {
                      return (
                        <div
                          className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-rounded-[3.125rem] naxatw-border naxatw-border-primary-300 naxatw-px-2 naxatw-py-1 naxatw-capitalize"
                          key={tag}
                        >
                          <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                            {tag}
                          </p>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>

        {/* summary */}
        <div className="summary">
          <p
            className={`naxatw-text-xs naxatw-font-normal naxatw-leading-4 naxatw-text-matt-200 ${isOpen ? '' : 'naxatw-line-clamp-[15]'}`}
            ref={summaryRef}
          >
            {summary || 'No summary to show.'}
          </p>
          {showSeeMoreBtn && (
            <button
              type="button"
              className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#41588F]"
              onClick={() => setIsOpen(!isOpen)}
            >
              See {isOpen ? 'Less' : 'More'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(KnowledgeCard);
