/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { deleteProgramByID, getProgram } from '@Services/program';
import StatusChips from '@Components/common/StatusChips';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Portal from '@Components/common/Layouts/Portal';

import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { toast } from 'react-toastify';
import { useTypedSelector } from '@Store/hooks';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const ProgramsTable = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [idToDelete, setIdtoDelete] = useState<string>('');
  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.programmes,
  );

  const {
    mutate: deleteProgram,
    isError,
    error,
    isLoading,
  } = useMutation({
    // mutationFn: () => deleteProgramByID(idToDelete),
    mutationFn: () => deleteProgramByID(idToDelete),
    onSuccess: () => {
      toast.success('Delete Success');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({ queryKey: ['program'] });
    },
  });

  const columns = [
    {
      header: 'Code',
      accessorKey: 'code',
    },
    {
      header: 'PROGRAMME',
      accessorKey: 'name',
    },
    // {
    //   header: 'FIRST TIER PARTNER',
    //   accessorKey: 'first_tier_partner',
    //   cell: ({ row }: any) => {
    //     return (
    //       <span>
    //         {row?.original?.first_tier_partners_name?.toString() || '-'}
    //       </span>
    //     );
    //   },
    // },
    {
      header: 'TYPE OF PROGRAMME',
      accessorKey: 'program_type',
    },
    {
      header: 'START DATE',
      accessorKey: 'start_date',
    },
    {
      header: 'END DATE',
      accessorKey: 'end_date',
    },
    {
      header: '',
      accessorKey: 'status',
      cell: ({ row }: any) => (
        <StatusChips
          label={row.original.status}
          status={
            row.original.status === 'Ongoing'
              ? 'success'
              : row.original.status === 'Completed'
                ? 'info'
                : 'error'
          }
        />
      ),
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;

        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-5">
            <ToolTip
              name="open_in_new"
              message="Programme Details"
              className="naxatw-text-icon-md naxatw-text-matt-200 naxatw-duration-200"
              iconClick={() => {
                navigate(`/explore-by/programmes/${cellId}`);
              }}
            />
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-200 naxatw-duration-200"
              iconClick={() => {
                navigate(`/data-bank/programmes/edit/${cellId}`);
              }}
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setIdtoDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];
  return (
    <div className="naxatw-h-[calc(100%-10rem)] naxatw-w-full sm:naxatw-h-[calc(100%-6.875rem)] lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="program"
        queryFn={getProgram}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteProgram()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(ProgramsTable);
