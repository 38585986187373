import Icon from '@Components/common/Icon';

interface INoDataAvailableProps {
  hasCloseBtn?: boolean;
  onClose?: () => void;
  text?: string;
}

const AdditionalInfoPopup = ({
  hasCloseBtn,
  onClose = () => {},
  text,
}: INoDataAvailableProps) => {
  return (
    <div className="naxatw-absolute naxatw-right-5 naxatw-top-5 naxatw-z-10 naxatw-max-w-[16.5rem] naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-p-2">
      {hasCloseBtn && (
        <div className="naxatw-absolute naxatw-right-[0.35rem] naxatw-top-[0.35rem] naxatw-cursor-pointer naxatw-rounded-full hover:naxatw-bg-green-100">
          <Icon
            name="close"
            className="naxatw-text-[0.85rem]"
            onClick={() => onClose()}
          />
        </div>
      )}
      <div className="naxatw-mb-1 naxatw-flex  naxatw-items-center naxatw-gap-1">
        <p className=" naxatw-text-[0.8rem] naxatw-font-bold naxatw-leading-4 naxatw-text-[#833177]">
          Note
        </p>{' '}
      </div>
      <p className="naxatw-py-0 naxatw-text-xs naxatw-leading-4 naxatw-text-matt-200">
        {text || ''}
      </p>
    </div>
  );
};

export default AdditionalInfoPopup;
