/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useSpring, animated } from '@react-spring/web';
import { useEffect, useState } from 'react';
import Portal from '@Components/common/Layouts/Portal';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import DropDown from '@Components/common/DropDown';
import { getProvinceList } from '@Services/components';
import { useQuery } from '@tanstack/react-query';
import {
  getGoalsList,
  getProvincialSummary,
  getFederalMinistry,
  getProvinicialMinstry,
} from '@Services/goals';
import isEmpty from '@Utils/isEmpty';
import GoalSkeleton from '@Components/common/FormComponent/GoalSkeleton';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { toast } from 'react-toastify';
import {
  includeAllProvincial,
  includeProvinicialMinstry,
} from '@Constants/FormConstants/goalsForm';
import ProvincialPriorities from './ProvincialPriorities';
import ProvincialSummary from './ProvincialSummary';
import ProvincialMinistry from './ProvincialMinistry';
import ProvincialSummaryForm from './ProvincialSummary/ProvincialSummaryForm';
import ProvincialMinistryForm from './ProvincialMinistry/ProvincialMinistryForm';

const ProvincialProfile = () => {
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const locationParam = queryParams.get('location') || 'Lumbini Province';
  const { pathname } = location;
  const [_, setSearchParams] = useSearchParams();

  const [selectedLocation, setSelectedLocation] = useState(
    locationParam || 'Lumbini Province',
  );

  // fetches province list
  const { data: provinceList, isFetching: isFetchingProvince } = useQuery({
    queryKey: ['get-province-list'],
    queryFn: () => getProvinceList(),
    select: (data: Record<string, any>) => {
      const filteredData = data?.data.map((options: Record<string, any>) => ({
        id: options?.id,
        label: options?.name,
        value: options?.id,
      }));
      const finalData = [
        ...filteredData,
        { id: null, label: 'Federal', value: 'federal' },
      ];
      return finalData;
    },
  });

  // fetches goals location
  const { data: goalsLists, isFetching: isFetchingGoalList } = useQuery({
    queryKey: ['goals-list', selectedLocation, provinceList],
    queryFn: () =>
      getGoalsList({
        province: provinceList?.find(list => list.label === selectedLocation)
          ?.id,
      }),
    enabled: !!provinceList,
    select: (lists: any) => {
      if (isEmpty(lists?.data?.results)) return {};
      const { province, province__name: provinceName } = lists.data.results[0];

      const goalslists = lists.data.results.map(
        (result: Record<string, any>) => ({
          id: result.id,
          goal: result.goal,
          programs: result.programs.map((program: Record<string, any>) => ({
            program__id: program.program__id,
            program__abbreviation: program.program__abbreviation,
            id: program.id,
          })),
        }),
      );

      return {
        province,
        provinceName,
        goalslists,
      };
    },
    onError: () => toast.error('Error Occured!. Please try again.'),
  });

  const selectedProvinceId = provinceList?.find(
    list => list.label === selectedLocation,
  )?.id;

  // fetch provincial summary
  const { data: provincialSummary, isFetching: isProvincialSummaryFetching } =
    useQuery({
      queryKey: ['provinicial-summary', selectedLocation, provinceList],
      queryFn: () => getProvincialSummary(selectedProvinceId),
      enabled: !!selectedProvinceId,
      select: list => list?.data[0],
      onError: () => toast.error('Error Occured!. Please try again.'),
    });

  // fetch provincial ministry
  const { data: provincialMinistry, isFetching: isProvincialMinistryFetching } =
    useQuery({
      queryKey: ['provinicial-ministry', selectedLocation, provinceList],
      queryFn: () =>
        selectedLocation === 'Federal'
          ? getFederalMinistry()
          : getProvinicialMinstry({
              provinceId: selectedProvinceId,
              type: 'Province Level',
            }),
      enabled: !!selectedProvinceId || selectedLocation === 'Federal',
      select: (list: any) => list?.data,
      onError: () => toast.error('Error Occured!. Please try again.'),
    });

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  const isallDataFetching =
    isProvincialSummaryFetching &&
    isProvincialMinistryFetching &&
    isFetchingGoalList;

  return (
    <animated.section
      style={{ ...springs }}
      className="provicial-section naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
    >
      <div className="provincial header naxatw-flex naxatw-w-full naxatw-flex-col naxatw-px-4 lg:naxatw-flex-row lg:naxatw-items-center lg:naxatw-justify-between">
        <div className="title">
          <span className="naxatw-text-2xl naxatw-font-medium naxatw-tracking-[-0.5px] naxatw-text-[#0B2E62]">
            Provincial Profile
          </span>
        </div>
        <div className="province dropdown naxatw-flex naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-100">
            Location
          </p>
          {isallDataFetching ? (
            <Skeleton className="naxatw-h-10 naxatw-w-[12.5rem]" />
          ) : (
            <DropDown
              options={provinceList || []}
              className="naxatw-min-w-[15.5rem]"
              choose="label"
              value={selectedLocation}
              onChange={e => {
                setSearchParams(`location=${e}`);
                setSelectedLocation(e);
              }}
              enableSearchbar={false}
            />
          )}
        </div>
      </div>

      <div className="naxatw-grid naxatw-gap-4 lg:naxatw-grid-cols-3">
        {includeAllProvincial?.includes(selectedLocation) ? (
          <div className="srcollbar naxatw-h-full naxatw-overflow-y-auto lg:naxatw-h-[33.635rem] ">
            {isallDataFetching ? (
              <GoalSkeleton />
            ) : (
              <ProvincialSummary
                setToggleOn={() => setToggleForm(true)}
                selectedLocationId={selectedProvinceId || ''}
                provincialSummaryList={provincialSummary || {}}
                location={selectedLocation}
              />
            )}
          </div>
        ) : null}

        {includeAllProvincial?.includes(selectedLocation) ? (
          <div className="srcollbar naxatw-h-full lg:naxatw-h-[33.635rem]">
            {isallDataFetching ? (
              <GoalSkeleton />
            ) : (
              <ProvincialPriorities
                goalsList={goalsLists || {}}
                selectedLocationId={selectedProvinceId || ''}
                location={selectedLocation}
              />
            )}
          </div>
        ) : null}

        {includeProvinicialMinstry?.includes(selectedLocation) && (
          <div className="srcollbar naxatw-h-full lg:naxatw-h-[33.635rem]">
            {isallDataFetching ? (
              <GoalSkeleton />
            ) : (
              <ProvincialMinistry
                setToggleOn={() => setToggleForm(true)}
                selectedLocationId={
                  selectedLocation.toLowerCase() === 'federal'
                    ? 'federal'
                    : selectedProvinceId || ''
                }
                ministryData={provincialMinistry || []}
                location={selectedLocation}
              />
            )}
          </div>
        )}
      </div>
      {toggleForm && pathname.includes('summary') && (
        <Portal>
          <ProvincialSummaryForm
            onClose={() => {
              navigate(
                `/data-bank/provincial-profile?location:${selectedLocation}`,
              );
              setToggleForm(false);
            }}
          />
        </Portal>
      )}

      {toggleForm && pathname.includes('ministry') && (
        <Portal>
          <ProvincialMinistryForm
            onClose={() => {
              navigate(
                `/data-bank/provincial-profile?location:${selectedLocation}`,
              );
              setToggleForm(false);
            }}
          />
        </Portal>
      )}
    </animated.section>
  );
};

export default hasErrorBoundary(ProvincialProfile);
