import { useState } from 'react';
import { FormControl } from '@Components/common/FormUI';
import { FlexColumn } from '@Components/common/Layouts';
import { Controller, useFormContext } from 'react-hook-form';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useQuery } from '@tanstack/react-query';
import { getSectorList } from '@Services/components';
import GroupCheckBox from '@Components/common/FormUI/GroupCheckBox';
import CheckBoxSkeleton from '@Components/common/FormComponent/CheckBoxSkeleton';
import { ComponentFormValidationSchemaProps } from '@Validations/ComponentForm';
import ErrorMessage from '@Components/common/ErrorMessage';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import Searchbar from '@Components/common/SearchBar';

const SectorComponentForm = () => {
  const [searchSector, setSearchSector] = useState('');

  const { data: sectorList, isFetching } = useQuery({
    queryKey: ['sector', searchSector],
    queryFn: () =>
      getSectorList({
        search: searchSector,
      }),
    select: data => data?.data,
  });

  const [value, handleChange] = useDebouncedInput({
    ms: 400,
    init: searchSector,
    onChange: debouncedEvent => setSearchSector(debouncedEvent.target.value),
  });

  const {
    control,
    formState: { errors },
  } = useFormContext<ComponentFormValidationSchemaProps>();

  return (
    <div className="sector-form-container">
      <div className="naxatw-px-6 naxatw-pb-2">
        <Searchbar
          placeholder="Search Sector"
          value={value}
          onChange={handleChange}
          required={false}
        />
        <>
          {
            /* @ts-ignore */
            errors?.sector?.message && (
              <ErrorMessage
                className="naxatw-ml-3 naxatw-mt-2"
                /* @ts-ignore */
                message={errors?.sector?.message}
              />
            )
          }
        </>
      </div>
      <div
        className={`scrollbar naxatw-w-full naxatw-overflow-y-auto naxatw-px-6  ${errors?.sector?.message ? 'naxatw-h-[calc(100vh-520px)] lg:naxatw-h-[calc(100vh-375px)]' : 'naxatw-h-[calc(100vh-490px)] lg:naxatw-h-[calc(100vh-350px)]'}`}
      >
        <FlexColumn className="naxatw-w-full naxatw-space-y-5">
          {isFetching ? (
            <CheckBoxSkeleton />
          ) : (
            <>
              <FormControl className="naxatw-w-full naxatw-gap-[0.5rem] naxatw-pb-2 ">
                <Controller
                  control={control}
                  name="sector"
                  defaultValue={{}}
                  render={({ field }) => {
                    return (
                      <GroupCheckBox
                        checkedItems={field.value} // Pass the current value of 'sector' to GroupCheckBox
                        onCheckboxChange={newCheckedItems =>
                          field.onChange(newCheckedItems)
                        } // Pass the change handler to update the form state
                        groupData={sectorList}
                        subGroupName="sector"
                        isParentOnly
                      />
                    );
                  }}
                />
              </FormControl>
            </>
          )}
        </FlexColumn>
      </div>
    </div>
  );
};

export default hasErrorBoundary(SectorComponentForm);
