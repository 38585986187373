import AdminHeader from '@Components/common/AdminHeader';
import { useSpring, animated } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { knowledgeRepoFormTypeInformation } from '@Constants/FormConstants/knowledgeRepoConstants';
import HeaderSwicthTab from '@Components/common/HeaderSwicthTab';
import KnowledgeRepositoryTable from './KnowledgeRepositoryTable';
import KnowledgeRepositoryForm from './KnowldgeRepositoryForm';

const KnowledgeRepository = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(pathname || '');

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <animated.div
      style={{ ...springs }}
      className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
    >
      <AdminHeader
        title="Knowledge Repository"
        onAddNewClick={() => {
          navigate('/data-bank/knowledge-repository/add');
        }}
        registerButtonText="Add Document"
      />
      <HeaderSwicthTab
        headerOptions={knowledgeRepoFormTypeInformation || []}
        activeTab={activeTab || ''}
        onChange={(path: string) => setActiveTab(path)}
      />
      <KnowledgeRepositoryTable activeTab={activeTab} />
      {toggleForm && (
        <Portal>
          <KnowledgeRepositoryForm
            onClose={() => {
              navigate('/data-bank/knowledge-repository');
            }}
          />
        </Portal>
      )}
    </animated.div>
  );
};

export default hasErrorBoundary(KnowledgeRepository);
