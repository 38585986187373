/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import InputLabel from '@Components/common/InputLabel';
import { FormControl, Input } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';
import { zodResolver } from '@hookform/resolvers/zod';
import { SignupFormValidationSchema } from '@Validations/signupForm/signupForm';
// import getInputElement from '@Components/common/FormComponent/GetInputElement';
import { signUpUser, postUser } from '@Services/common';
import { toast } from 'react-toastify';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  password: '',
  confirmPassword: '',
  role: 'General User',
  // role: '',
};

const SignUp = () => {
  const navigate = useNavigate();

  const [pwVisibility, setPwVisibility] = useState(false);
  const [confirmPwVisibility, setConfirmPwVisibility] = useState(false);

  // const userRoles = [
  //   { id: 1, value: 'System Admin', label: 'System Admin' },
  //   { id: 2, value: 'Program Manager', label: 'Program Manager' },
  // ];
  const formMethods = useForm({
    mode: 'onChange',
    resolver: zodResolver(SignupFormValidationSchema),
    defaultValues: initialState,
  });

  const {
    handleSubmit,
    // getValues,
    // setValue,
    formState: { errors },
    register,
    // control,
    watch,
  } = formMethods;

  const formValues = watch();

  const { mutateAsync: postUserSignUp, isLoading: isUserSignUpLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) => signUpUser(payloadData),
      onError: (error: Record<string, any>) => {
        toast.error(error?.response?.data?.message || 'User sign-up failed');
      },
    });
  const { mutateAsync: postUserProfile, isLoading: isUserProfileLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) => postUser(payloadData),
      onError: (error: Record<string, any>) => {
        toast.error(
          error?.response?.data?.message || 'User profile creation failed',
        );
      },
    });

  const handlePostUser = async (formData: Record<string, any>) => {
    const { first_name, last_name, email, username, password } = formData;

    const signUpResponse = await postUserSignUp({
      first_name,
      last_name,
      email,
      username,
      password,
    });
    navigate('./verify-email');
    await postUserProfile({
      user: signUpResponse?.data?.id,
      first_name,
      last_name,
      username,
      email,
      password,
    });
  };
  // the function validates for fcdo email at production
  const handleFormSubmit = async () => {
    const { email } = formValues;
    if (email.split('@')[1] !== 'fcdo.gov.uk') {
      if (process.env.NODE_ENV !== 'production') {
        // Continue the function without returning
        await handlePostUser(formValues);
        toast.success(`User Created successfully`);
        return;
      }
      navigate('./verify-fcdo-email');
      return;
    }
    await handlePostUser(formValues);
    toast.success(`User  Created successfully`);
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="naxatw-flex naxatw-flex-col naxatw-gap-4"
    >
      <h6 className="naxatw-text-xl naxatw-font-medium naxatw-text-black">
        Sign Up
      </h6>
      <FlexColumn className="naxatw-gap-3">
        <FlexRow className="naxatw-gap-2">
          <FormControl>
            <InputLabel label="First Name" astric />
            <Input
              id="first_name"
              placeholder="Enter Name"
              type="text"
              className="naxatw-mt-2"
              {...register('first_name')}
            />
            {errors.first_name && (
              <p className="naxatw-text-sm naxatw-text-red-500">
                {errors.first_name.message}
              </p>
            )}
          </FormControl>
          <FormControl>
            <InputLabel label="Last Name" />
            <Input
              id="last_name"
              placeholder="Enter Last Name"
              type="text"
              className="naxatw-mt-2"
              {...register('last_name')}
            />
            {errors.last_name && (
              <p className="naxatw-text-sm naxatw-text-red-500">
                {errors.last_name.message}
              </p>
            )}
          </FormControl>
        </FlexRow>
        <FormControl>
          <InputLabel label="Email" astric />
          <Input
            id="email"
            placeholder="Enter Email"
            type="text"
            className="naxatw-mt-2"
            {...register('email')}
          />
          {errors.email && (
            <p className="naxatw-text-sm naxatw-text-red-500">
              {errors.email.message}
            </p>
          )}
        </FormControl>
        <FormControl>
          <InputLabel label="Username" astric />
          <Input
            id="username"
            placeholder="Enter your Username"
            type="text"
            className="naxatw-mt-2"
            {...register('username')}
          />
          {errors.username && (
            <p className="naxatw-text-sm naxatw-text-red-500">
              {errors.username.message}
            </p>
          )}
        </FormControl>
        {/* <FormControl className="naxatw-relative">
          <InputLabel label="Select Role for User" astric />
          {getInputElement(
            {
              id: 'role',
              placeholder: 'Select Role for User',
              type: 'select',
              name: 'role',
            },
            // @ts-ignore
            formProps,
            userRoles,

            // { register },
          )}
          {errors.role && (
            <p className="naxatw-text-sm naxatw-text-red-500">
              {errors.role.message}
            </p>
          )}
        </FormControl> */}
        <FormControl className="naxatw-relative">
          <InputLabel label="Password" astric />
          <Input
            id="password"
            placeholder="Enter your Password"
            type={pwVisibility ? 'text' : 'password'}
            className="naxatw-mt-2"
            {...register('password')}
          />
          <Icon
            name={pwVisibility ? 'visibility' : 'visibility_off'}
            onClick={() => setPwVisibility(!pwVisibility)}
            className="naxatw-absolute naxatw-right-4 naxatw-top-[2.75rem] naxatw-select-none naxatw-text-xl"
          />
          {errors.password && (
            <p className="naxatw-text-sm naxatw-text-red-500">
              {errors.password.message}
            </p>
          )}
        </FormControl>

        <FormControl className="naxatw-relative">
          <InputLabel label="Confirm Password" astric />
          <Input
            id="confirm-password"
            placeholder="Enter Password Again"
            type={confirmPwVisibility ? 'text' : 'password'}
            className="naxatw-mt-2"
            {...register('confirmPassword')}
          />
          <Icon
            name={confirmPwVisibility ? 'visibility' : 'visibility_off'}
            onClick={() => setConfirmPwVisibility(!confirmPwVisibility)}
            className="naxatw-absolute naxatw-right-4 naxatw-top-[2.75rem] naxatw-select-none naxatw-text-xl"
          />
          {errors.confirmPassword && (
            <p className="naxatw-text-sm naxatw-text-red-500">
              {errors.confirmPassword.message}
            </p>
          )}
        </FormControl>
      </FlexColumn>
      <FlexColumn>
        <Button
          type="submit"
          isLoading={isUserSignUpLoading || isUserProfileLoading}
          disabled={isUserSignUpLoading || isUserProfileLoading}
        >
          Signup
        </Button>
        <FlexRow className="naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
            Already have an account ?{' '}
          </p>
          <Button
            type="button"
            variant="link"
            className="naxatw-w-fit !naxatw-p-0 naxatw-font-medium"
            onClick={() => {
              navigate('../login');
            }}
          >
            <p className="naxatw-underline">Login</p>
          </Button>
        </FlexRow>
      </FlexColumn>
    </form>
  );
};

export default SignUp;
