/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useTypedSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMarkerCategories, getMarkerCategories } from '@Services/markers';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const MarkerCategoriesTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [iDToDelete, setIDToDelete] = useState<string>('');

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['markers/markers-categories'],
  );

  const {
    mutate: deleteMarkerCategoriesData,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteMarkerCategories(iDToDelete),
    onSuccess: () => {
      toast.success('Marker category deleted successfully');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['marker-categories-tabledata'],
      });
    },
  });

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'NAME',
      accessorKey: 'name',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() => {
                navigate(
                  `/data-bank/markers/markers-categories/edit/${cellId}`,
                );
              }}
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];
  return (
    <div className="naxatw-h-[calc(100%-7rem)] naxatw-w-full lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="marker-categories-tabledata"
        queryFn={getMarkerCategories}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteMarkerCategoriesData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(MarkerCategoriesTable);
