import { api, authenticated, fastApi } from '.';

export const getComponentDropdown = (params: Record<string, any>) => {
  return authenticated(api).get('/project-dropdown/', { params });
};

export const getOrganizationDropdown = () => {
  return authenticated(api).get('/organization-dropdown/');
};

export const getProgramDropdown = () => {
  return authenticated(api).get('/program-dropdown/');
};

export const getReportingPeriodBudget = (params: Record<string, any>) => {
  return authenticated(api).get('/reporting-period-budget/', { params });
};

export const getPortfolioRegistration = (params: Record<string, any>) => {
  return authenticated(fastApi).get(`/core/project-data/`, { params });
};

export const getSinglePartnerReporting = (id: number) => {
  return authenticated(api).get(`/project-data/${id}/`);
};

export const postPartnerReporting = (data: any) => {
  return authenticated(api).post(`/project-data/`, data);
};

export const updatePartnerReporting = (id: number, data: any) => {
  return authenticated(api).patch(`/project-data/${id}/`, data);
};

export const deletePortfolioRegistration = (id: string) => {
  return authenticated(api).delete(`/project-data/${id}/`);
};

export const getProvince = () => {
  return authenticated(api).get(`/province/`);
};

export const getDistrict = (params: Record<string, any>) => {
  return authenticated(api).get(`/district/`, { params });
};

export const getMunicipality = (params: Record<string, any>) => {
  return authenticated(api).get(`/municipality/`, { params });
};

export const getLevelOfIntervention = () => {
  return authenticated(api).get(`/choice/?type=working_area`);
};

export const getPortfolioRegistrationById = (id: any) => {
  return authenticated(api).get(`/portfolio/${id}/`);
};

export const getStakehoderPortfolio = (id: any) => {
  return authenticated(api).get(`/stakeholder/${id}/`);
};

export const getOtherStakehoderPortfolio = (id: any) => {
  return authenticated(api).get(`/stakeholder-partner-info/?portfolio=${id}`);
};

export const getOutreachBudgetPortfolio = (id: any) => {
  return authenticated(api).get(
    `/portfolio-partner-outreach-budget/?portfolio=${id}`,
  );
};

export const getDocumentPortfolio = (id: any) => {
  return authenticated(api).get(`/portfolio-document/?portfolio=${id}`);
};

export const postStakeholderPortfolio = (data: any) => {
  return authenticated(api).post(`/stakeholder/`, data);
};

export const postOtherStakeholderPortfolio = (data: any) => {
  return authenticated(api).post(`/stakeholder-partner-info/`, data);
};

export const postOutreachBudgetPortfolio = (data: any) => {
  return authenticated(api).post(`/portfolio-partner-outreach-budget/`, data);
};

export const postDocumentPortfolio = (data: any) => {
  return authenticated(api).post(`/portfolio-document/`, data);
};

export const patchStakeholderPortfolio = (id: string, data: any) => {
  return authenticated(api).patch(`/stakeholder/${id}/`, data);
};

export const patchOtherStakeholderPortfolio = (id: string, data: any) => {
  return authenticated(api).patch(`/stakeholder-partner-info/${id}/`, data);
};

export const patchOutreachBudgetPortfolio = (
  id: string,
  data: Record<string, any>,
) => {
  return authenticated(api).patch(
    `/portfolio-partner-outreach-budget/${id}/`,
    data,
  );
};

export const deleteOtherStakeholderPortfolio = (id: number) => {
  return authenticated(api).delete(`/stakeholder-partner-info/${id}/`);
};

export const deleteDocumentPortfolio = (id: string) => {
  return authenticated(api).delete(`/portfolio-document/${id}/`);
};

export const deleteOutreachBudgetPortfolio = (id: number) => {
  return authenticated(api).delete(`/portfolio-partner-outreach-budget/${id}/`);
};

export const deleteWorkingArea = (id: number) => {
  return authenticated(api).delete(`working-area/${id}/`);
};

export const patchWorkingArea = (id: number, data: Record<string, any>) => {
  return authenticated(api).patch(`working-area/${id}/`, data);
};

// dropdown options
export const getPartnersList = () => {
  return authenticated(api).get(`/organization-dropdown/`);
};

export const getSectorsList = () => {
  return authenticated(api).get(`/sector-group-dropdown/`);
};

export const getTypeOfSupport = () => {
  return authenticated(api).get(`/choice/?type=support_type`);
};

export const getPeriodOfReporting = () => {
  return authenticated(api).get(`/choice/?type=reporting_period`);
};

// portfolio-indicators
export const getProgramComponentIndicators = (id: string) => {
  return authenticated(api).get(
    `/get_program_component_indicators/?component=${id}`,
  );
};

export const getPortfolioIndicatorValues = (id: string) => {
  return authenticated(api).get(
    `/get_portfolio_indicator_values/?portfolio=${id}`,
  );
};

export const getFirstTierPartner = (params: Record<string, any>) => {
  return authenticated(api).get(`/project-data-first-tier-partner/`, {
    params,
  });
};

export const postFocalPersonPartnerReporting = (data: any) => {
  return authenticated(api).post('/focal-person/', data);
};
export const deleteFocalPersonPartner = (id: number) => {
  return authenticated(api).delete(`/focal-person/${id}/`);
};

export const getWorkingAreas = (params: Record<string, any>) => {
  return authenticated(api).get(`/working-area/`, { params });
};

export const postWorkingAreasData = (data: Record<string, any>) => {
  return authenticated(api).post(`/working-area/`, data);
};

export const postAllocatedBudgetData = (data: Record<string, any>) => {
  return authenticated(api).post(`/project-data-budget/`, data);
};
export const patchAllocatedBudgetData = (id: number, data: any) => {
  return authenticated(api).patch(`/project-data-budget/${id}/`, data);
};

export const getAllocatedBudgetData = (params: Record<string, any>) => {
  return authenticated(api).get(`/project-data-budget/`, { params });
};
export const deleteAllocatedBudget = (id: number) => {
  return authenticated(api).delete(`/project-data-budget/${id}/`);
};

export const getIndicatorByComponent = (params: Record<string, any>) => {
  return authenticated(api).get(`/indicator-by-component/`, { params });
};

export const getIndicatorReporting = (params: Record<string, any>) => {
  return authenticated(api).get(`/project-data-indicator/`, { params });
};

export const postIndicatorData = (data: Record<string, any>) => {
  return authenticated(api).post(`/project-data-indicator/`, data);
};
export const patchIndicatorData = (id: number, data: any) => {
  return authenticated(api).patch(`/project-data-indicator/${id}/`, data);
};

export const getOthersDPData = (params: Record<string, any>) => {
  return authenticated(api).get(`/project-data-development-partner/`, {
    params,
  });
};

export const postOthersDPData = (data: Record<string, any>) => {
  return authenticated(api).post(`/project-data-development-partner/`, data);
};
export const patchOthersDPData = (id: number, data: any) => {
  return authenticated(api).patch(
    `/project-data-development-partner/${id}/`,
    data,
  );
};

export const deleteOthersDPData = (id: number) => {
  return authenticated(api).delete(`/project-data-development-partner/${id}/`);
};

export const getUnitMinistryList = (params: Record<string, any>) => {
  return authenticated(api).get(`/get-ministry-by-type/`, { params });
};
export const getUnitMinistryDepartmentList = (params: Record<string, any>) => {
  return authenticated(api).get(`/get-unit-ministry/`, { params });
};

export const postStakeholderData = (data: Record<string, any>) => {
  return authenticated(api).post(`/project-data-stakeholder/`, data);
};
export const patchStakeholderData = (id: number, data: any) => {
  return authenticated(api).patch(`/project-data-stakeholder/${id}/`, data);
};
export const getStakeholderData = (params: Record<string, any>) => {
  return authenticated(api).get(`/project-data-stakeholder/`, {
    params,
  });
};
export const deleteStakeholderData = (id: number) => {
  return authenticated(api).delete(`/project-data-stakeholder/${id}/`);
};

export const postPartnerReportingDoc = (payload: Record<string, any>) => {
  return authenticated(api).post(`/project-data-document/`, payload);
};
export const patchPartnerReportingDoc = (
  id: number,
  payload: Record<string, any>,
) => {
  return authenticated(api).patch(`/project-data-document/${id}/`, payload);
};
export const getPartnerReportingData = (params: Record<string, any>) => {
  return authenticated(api).get(`/project-data-document/`, {
    params,
  });
};
export const postPartnerReportingUploadDoc = (payload: Record<string, any>) => {
  return authenticated(api).post(`/project-data-upload-docs/`, payload);
};

export const getPartnerReportingUploadData = (params: Record<string, any>) => {
  return authenticated(api).get(`/project-data-upload-docs/`, {
    params,
  });
};

export const getPreviousBudgetData = (params: Record<string, any>) => {
  return authenticated(api).get(`/period-wise-budget/`, {
    params,
  });
};

export const getChoiceData = (params: Record<string, any>) => {
  return authenticated(api).get(`/choice/`, {
    params,
  });
};

export const getGroupedMunicipality = (params: any) => {
  return authenticated(api).get(
    `/districtwise-municipality/?district=${params}`,
  );
};

export const getGroupedDistrict = (params: Record<string, any>) => {
  return authenticated(api).get(
    `/provincewise-district/?province=${params.province}`,
  );
};
