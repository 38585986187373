import { FC, useEffect, useState } from 'react';
import { cn } from '@Utils/index';
import { Button } from '@Components/RadixComponents/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
} from '@Components/RadixComponents/Popover';
import Icon from '@Components/common/Icon';
import { Calendar } from '@Components/RadixComponents/Calendar';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { MultipleDatePickerProps } from '@Constants/interface';
import { datePickerStyles } from '@Constants/datepicker';

// hadle selected date change
const handleDateChange = (date: any, onDateChange: any) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDateString = `${year}-${String(month).padStart(
    2,
    '0',
  )}-${String(day).padStart(2, '0')}`;
  if (onDateChange) {
    onDateChange(formattedDateString);
  }
};

const DateRangeIcon = ({ icon }: { icon: string }) => {
  return (
    <Icon
      name={icon}
      className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-shrink-0 naxatw-items-center naxatw-justify-center naxatw-text-[24px] naxatw-font-light naxatw-leading-6 naxatw-text-matt-200 group-hover:naxatw-text-secondary-500"
    />
  );
};

const MultipleDatePicker: FC<MultipleDatePickerProps> = ({
  placeHolder = 'Select Date Range',
  enableButton = false,
  dateIcon = 'event',
  contentAlign = 'end',
  contentSideOffset = 0,
  showContentArrow = false,
  datePickerColor = 'naxatw-bg-[#833177] hover:naxatw-bg-[#833177]',
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  clearDateRange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | string>(
    startDate,
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | string>(
    endDate,
  );

  useEffect(() => {
    if (!startDate) {
      setSelectedStartDate('');
    }
    if (!endDate) {
      setSelectedEndDate('');
    }
  }, [startDate, endDate]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        {enableButton ? (
          <Button
            variant="dropDown"
            size="drop-lg"
            className={cn(
              'naxatw-group naxatw-flex naxatw-w-full naxatw-items-center  naxatw-justify-between naxatw-gap-2 naxatw-font-normal hover:naxatw-border-secondary-500',
            )}
          >
            <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
              <span className="naxatw-text-[0.875rem] naxatw-text-matt-200">
                {startDate && endDate
                  ? `${startDate} - ${endDate}`
                  : startDate || endDate || placeHolder}
              </span>
            </div>
            <DateRangeIcon icon="arrow_drop_down" />
          </Button>
        ) : (
          <div className="date-range-container">
            <DateRangeIcon icon={dateIcon} />
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent
        className="popover-content !naxatw-w-fit !naxatw-items-start  naxatw-bg-white !naxatw-p-2 !naxatw-px-3"
        align={contentAlign}
        sideOffset={contentSideOffset}
        // style={{ width: 'var(--radix-popover-trigger-width)' }}
      >
        <div className="naxatw-flex !naxatw-w-fit  naxatw-gap-8 naxatw-bg-white">
          {/* start month */}
          <div className="start-month-container naxatw-w-full">
            <p className="naxatw-mb-2 naxatw-text-[14px] naxatw-font-semibold  naxatw-text-matt-100">
              Start Date
            </p>
            <Calendar
              selected={new Date(selectedStartDate)}
              onSelect={(date: Date) => {
                setSelectedStartDate(date);
              }}
              defaultMonth={startDate || new Date()}
              classNames={{
                ...datePickerStyles,
                selected: `naxatw-rounded-md ${datePickerColor} !naxatw-text-white hover:!naxatw-text-white`,
              }}
            />
          </div>

          {/* end  month */}
          <div className="end-month-container naxatw-w-full">
            <p className="naxatw-mb-2 naxatw-text-[14px] naxatw-font-semibold  naxatw-text-matt-100">
              End Date
            </p>
            <Calendar
              selected={new Date(selectedEndDate)}
              disabled={{ before: new Date(`${selectedStartDate}`) }}
              onSelect={(date: Date) => {
                setSelectedEndDate(date);
              }}
              defaultMonth={endDate || new Date()}
              classNames={{
                ...datePickerStyles,
                selected: `naxatw-rounded-md ${datePickerColor} !naxatw-text-white  hover:!naxatw-text-white hover:!${datePickerColor}`,
              }}
            />
          </div>
        </div>
        <div className="btns naxatw-mb-2 naxatw-mt-4 naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-5">
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className="naxatw-cursor-pointer naxatw-text-[0.875rem] naxatw-font-semibold naxatw-text-secondary-500"
            onClick={() => {
              clearDateRange();
              setSelectedEndDate('');
              setSelectedStartDate('');
            }}
          >
            Clear
          </span>
          <Button
            className="!naxatw-h-8 !naxatw-bg-secondary-500 !naxatw-px-4 !naxatw-py-2"
            onClick={() => {
              if (selectedStartDate) {
                handleDateChange(selectedStartDate, setStartDate);
              }
              if (selectedEndDate) {
                handleDateChange(selectedEndDate, setEndDate);
              }
              setIsOpen(false);
            }}
          >
            Apply
          </Button>
        </div>
        {showContentArrow ? <PopoverArrow /> : null}
      </PopoverContent>
    </Popover>
  );
};

export default hasErrorBoundary(MultipleDatePicker);
