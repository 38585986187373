/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import AddMoreButton from '@Components/common/AddMoreButton';
import Portal from '@Components/common/Layouts/Portal';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import groupFormElements, { removeObjectAtIndex } from '@Utils/index';
import { v4 as uuidv4 } from 'uuid';
import {
  defaultPartnerFocalPerson,
  defaultPartnerFocalPersonType,
  focalPersonFormFields,
} from '@Constants/FormConstants/portfolioReportingConstants';
import { useFormContext } from 'react-hook-form';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import ErrorMessage from '@Components/common/ErrorMessage';
import { Button } from '@Components/RadixComponents/Button';
import Flex from '@Components/common/Layouts/Flex';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { toast } from 'react-toastify';
import { focalPeopleSchema } from '@Validations/PortfolioReporting/portfolioRegistrationValidation';
import Icon from '@Components/common/Icon';
import { deleteFocalPersonPartner } from '@Services/portfolioReporting';
import { useMutation } from '@tanstack/react-query';

interface IFirstTierPartner {
  isLoading: boolean;
}

const FirstTierPartner = ({ isLoading }: IFirstTierPartner) => {
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [selectedFocalPerson, setSelectedFocalPerson] = useState<Record<
    string,
    any
  > | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);

  const [toggleModal, setToggleModal] = useState(false);
  const groupedFormFields = groupFormElements(focalPersonFormFields);

  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    watch,
  } = useFormContext();

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
  };

  const { first_tier_partner } = getValues();

  const focalPersonList: defaultPartnerFocalPersonType[] =
    watch('focal_person');

  const handleAddField = () => {
    setValue('focal_person', [
      ...focalPersonList,
      {
        ...defaultPartnerFocalPerson,
      },
    ]);
  };

  const handleDelete = async () => {
    if (deleteIndex === null) return;
    try {
      const tempFields = removeObjectAtIndex(focalPersonList, deleteIndex);
      setValue('focal_person', tempFields);
      setOpenDeleteConfirmation(false);
    } catch {
      toast.error('Something went wrong');
    }
  };

  const {
    mutateAsync: deleteFocalPerson,
    isError,
    error,
    isLoading: isDeleting,
  } = useMutation({
    mutationFn: (id: number) => deleteFocalPersonPartner(id),
    onError: () => {
      toast.error('Error Occured! Try again');
    },
  });

  const handleFocalPersonDelete = async (focalPerson: Record<string, any>) => {
    if (focalPerson.postData) {
      const newFocalPerson = first_tier_partner?.focal_person?.filter(
        (person: Record<string, any>) => person.id !== selectedFocalPerson?.id,
      );
      const newFirstTierPartnerValue = {
        ...first_tier_partner,
        focal_person: newFocalPerson,
      };
      setValue('first_tier_partner', newFirstTierPartnerValue);
    } else {
      await deleteFocalPerson(focalPerson.id);
      const newFocalPerson = first_tier_partner?.focal_person?.filter(
        (person: Record<string, any>) => person.id !== selectedFocalPerson?.id,
      );
      const newFirstTierPartnerValue = {
        ...first_tier_partner,
        focal_person: newFocalPerson,
      };
      setValue('first_tier_partner', newFirstTierPartnerValue);
      toast.success('Focal Person Deleted Succesfully');
    }
    setConfirmDelete(false);
    setSelectedFocalPerson(null);
  };

  const handleSave = () => {
    const newFocalPersons = focalPersonList.map(person => ({
      ...person,
      id: `new-${uuidv4()}`,
      postData: true,
      first_tier_partner: first_tier_partner?.first_tier_partner_id,
    }));
    const newFirstTierPartnerValue = {
      ...first_tier_partner,
      focal_person: [...first_tier_partner.focal_person, ...newFocalPersons],
    };
    setValue('first_tier_partner', newFirstTierPartnerValue);
    setValue('focal_person', [defaultPartnerFocalPerson]);
    setToggleModal(false);

    // try {
    //   focalPeopleSchema.parse(focalPersonList);
    //   setValue('focal_person', focalPersonList);
    //   setToggleModal(false);
    // } catch (error) {
    //   // console.log('error--->', error.message);
    // }
  };

  return (
    <>
      {openDeleteConfirmation && (
        <Portal>
          <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-h-[100vh] naxatw-w-[100vw] naxatw-bg-black naxatw-opacity-30" />
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={handleDelete}
            // isError={isError}
            // isLoading={isLoading}
            // error={error}
          />
        </Portal>
      )}

      {confirmDelete && (
        <Portal>
          <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-h-[100vh] naxatw-w-[100vw] naxatw-bg-black naxatw-opacity-30" />
          <DeleteConfirmationOverlay
            onClose={() => setConfirmDelete(false)}
            onDelete={() => {
              if (selectedFocalPerson) {
                handleFocalPersonDelete(selectedFocalPerson);
              }
            }}
            isError={isError}
            isLoading={isDeleting}
            error={error}
          />
        </Portal>
      )}
      {isLoading ? (
        <FormSkeleton numRows={1} />
      ) : (
        <div className="naxatw-w-full naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-4">
          <p className="naxatw-body-sm">
            {first_tier_partner?.first_tier_partner__name}
          </p>
        </div>
        // <FlexColumn className="naxatw-gap-3 naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-3">
        //   <p className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
        //     {first_tier_partner?.first_tier_partner_name || ''}
        //   </p>
        //   <FlexColumn>
        //     {first_tier_partner?.focal_person?.map(
        //       (person: Record<string, any>) => (
        //         <div
        //           className="naxatw-flex naxatw-items-center naxatw-justify-between
        //     "
        //         >
        //           <span>{person?.name}</span>
        //           <div className="naxatw-flex naxatw-items-center naxatw-gap-x-3">
        //             {/* <Icon name="edit" /> */}
        //             <IconButton
        //               name="delete"
        //               iconClassName="naxatw-text-[#CED5DF] naxatw-duration-200 hover:!naxatw-text-red-600 naxatw-cursor-pointer"
        //               onClick={() => {
        //                 setSelectedFocalPerson(person);
        //                 setConfirmDelete(true);
        //               }}
        //             />
        //           </div>
        //         </div>
        //       ),
        //     )}
        //   </FlexColumn>

        //   <AddMoreButton
        //     onClick={() => setToggleModal(true)}
        //     btnText="Add Focal Person"
        //     textClassname="naxatw-font-bold"
        //   />
        // </FlexColumn>
      )}
      {toggleModal && (
        <Portal>
          <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] naxatw-max-w-[42.5rem] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 md:naxatw-min-w-[32rem] xl:naxatw-w-[68.75rem]">
            <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
              <BreadCrumb
                heading="Add a Focal Person / Form"
                overlayStatus={() => setToggleModal(false)}
              />
              <IconButton
                name="close"
                className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
                iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
                onClick={() => setToggleModal(false)}
              />
            </div>
            <div className="scrollbar naxatw-h-[calc(100%-4.875rem)] naxatw-overflow-y-scroll naxatw-px-10 naxatw-py-8">
              <FlexColumn className="naxatw-gap-[1.125rem]">
                {focalPersonList?.map((focalPerson, index: number) => (
                  <div
                    className="naxatw-space naxatw-flex naxatw-w-full naxatw-flex-col naxatw-space-y-5 naxatw-rounded-lg naxatw-border-[1px] naxatw-border-[#D0D5DD] naxatw-p-4"
                    key={index}
                  >
                    <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between">
                      <span className="naxatw-text-base naxatw-font-bold naxatw-leading-5 naxatw-text-[#393E4E]">
                        Focal Person {index + 1}
                      </span>
                      {focalPersonList.length > 1 && (
                        <IconButton
                          name="delete"
                          iconClassName="naxatw-text-[#CED5DF] naxatw-duration-200 hover:!naxatw-text-red-600 naxatw-cursor-pointer"
                          // disabled={otherStakeholderFields.length <= 1}
                          onClick={() => {
                            setDeleteIndex(index);
                            setOpenDeleteConfirmation(true);
                          }}
                        />
                      )}
                    </div>
                    <FlexColumn className="naxatw-w-full naxatw-space-y-5 ">
                      {groupedFormFields?.map(
                        (fieldRow: Record<string, any>, i) => (
                          <FlexRow key={i} className="naxatw-gap-x-5">
                            {fieldRow?.map((field: FormFieldProps) => {
                              const { id } = field;
                              return (
                                <FormControl
                                  className="naxatw-w-full naxatw-gap-[0.5rem] "
                                  key={`${field.id}- ${field.name}`}
                                >
                                  <InputLabel
                                    label={field.label}
                                    astric={field.required}
                                    id={field.id}
                                  />
                                  {getInputElement(
                                    {
                                      ...field,
                                      id: `focal_person.${index}.${field.id}`,
                                    },
                                    /* @ts-ignore */
                                    formProps,
                                    [],
                                  )}
                                  <>
                                    {
                                      /* @ts-ignore */
                                      formProps?.errors?.focalPerson?.[index]?.[
                                        id
                                      ] && (
                                        <ErrorMessage
                                          message={
                                            /* @ts-ignore */
                                            formProps?.errors?.focalPerson?.[
                                              index
                                            ]?.[id]?.message
                                          }
                                        />
                                      )
                                    }
                                  </>
                                </FormControl>
                              );
                            })}
                          </FlexRow>
                        ),
                      )}
                    </FlexColumn>
                  </div>
                ))}
                <AddMoreButton
                  btnText="Add More Focal Person"
                  onClick={() => {
                    handleAddField();
                  }}
                />
              </FlexColumn>
            </div>
            <Flex className="naxatw-bg-white naxatw-py-5">
              <Button
                type="button"
                size="normal"
                onClick={e => {
                  e.preventDefault();
                  handleSave();
                }}
                variant="primary"
                className="naxatw-mx-auto naxatw-w-fit"
              >
                Save
              </Button>
            </Flex>
          </div>
        </Portal>
      )}
    </>
  );
};

export default FirstTierPartner;
