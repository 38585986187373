/* eslint-disable import/prefer-default-export */
export const reportCheckList = [
  {
    id: 'allocated_budget',
    name: 'currency_pound',
    title: 'Allocated Budget',
  },
  {
    id: 'sectors',
    name: 'category',
    title: 'Sectors',
  },
  {
    id: 'markers',
    name: 'donut_small',
    title: 'Markers',
  },
  {
    id: 'campaign_goals',
    name: 'flag_circle',
    title: 'Campaign Goals',
  },
  {
    id: 'working_areas',
    name: 'place',
    title: 'Working Areas',
  },
  {
    id: 'stakeholders',
    name: 'groups',
    title: 'Stakeholders',
  },
];

export const provincialProfileCheckBoxData = [
  {
    id: 'provincial_priorities',
    name: 'donut_small',
    title: 'Provincial Priorites',
  },
  {
    id: 'programme_details',
    name: 'article',
    title: 'Programme Details',
  },
  // {
  //   id: 'sectors',
  //   name: 'category',
  //   title: 'Sectors',
  // },
  // {
  //   id: 'top_partners_by_budget_allocated',
  //   name: 'currency_pound',
  //   title: 'Top Partners by Budget Allocated',
  // },
  {
    id: 'working_palikas',
    name: 'place',
    title: 'Working Areas',
  },
  {
    id: 'stakeholders',
    name: 'groups',
    title: 'Stakeholders',
  },
];
export const programmeDetailsCheckboxData = [
  {
    id: 'project_details',
    name: 'folder_open',
    title: 'Project Details',
  },
  {
    id: 'sectors',
    name: 'category',
    title: 'Sectors',
  },
  {
    id: 'markers',
    name: 'donut_small',
    title: 'Markers',
  },
  {
    id: 'campaign_goals',
    name: 'flag_circle',
    title: 'Campaign Goals',
  },
  {
    id: 'working_areas',
    name: 'place',
    title: 'Working Areas',
  },
  {
    id: 'stakeholders',
    name: 'groups',
    title: 'Stakeholders',
  },
];
