import { useSpring, animated } from '@react-spring/web';

import { exploreByGeographyCardsData as geographyData } from '@Constants/exploreByGeography';
import Cards from './Cards';
import ExploreHeader from '../common/ExploreHeaders';

const ExploreByGeography = () => {
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <>
      <div className="naxatw-m-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-py-7">
        <div className="naxatw-mx-auto naxatw-w-11/12">
          <div className="naxatw-mb-5">
            <ExploreHeader />
          </div>
          <animated.div style={{ ...springs }}>
            <div className="naxatw-flex naxatw-items-center naxatw-justify-center lg:naxatw-h-[calc(100vh-15rem)]">
              <div className="naxatw-flex naxatw-items-center">
                <div className="naxatw-grid naxatw-w-full naxatw-grid-cols-2 naxatw-items-center naxatw-justify-start naxatw-gap-4 md:naxatw-grid-cols-2 lg:naxatw-grid-cols-3">
                  {geographyData.map(geography => (
                    <Cards
                      key={geography.provinceId}
                      provinceId={geography.provinceId}
                      img={geography.img}
                      heading={geography.heading}
                      link={geography.link}
                    />
                  ))}
                </div>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default ExploreByGeography;
